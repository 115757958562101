import { ExhibitorDetailFragment, ExhibitorForListFragment } from '../api'

export const createExhibitorsCSV = (
  exhibitors: Array<ExhibitorDetailFragment | ExhibitorForListFragment>
) => {
  let CSV: string[][] = []

  // const keys = [
  //   'accountId',
  //   'password',
  //   'exhibition',
  //   'composition',
  //   'receptionId',
  //   'country',
  //   'familyName',
  //   'givenName',
  //   'companyName',
  //   'companyNameKana',
  //   'department',
  //   'position',
  //   'postalCode',
  //   'prefecture',
  //   'municipality',
  //   'address',
  //   'email',
  //   'tel',
  // ]

  const keys = [
    'accountId',
    'password',
    'composition',
    // 'exhibition',
    'receptionId',
    'country',

    'familyName',
    'givenName',
    'department',
    'position',
    'email',

    'familyName2',
    'givenName2',
    'department2',
    'position2',
    'email2',

    'familyName3',
    'givenName3',
    'department3',
    'position3',
    'email3',

    'companyName',
    'companyNameKana',
    '',
    '',
    'postalCode',
    'prefecture',
    'municipality',
    'address',
    'tel',
  ]

  const wordMap: { [key: string]: string } = {
    accountId: 'アカウントID',
    password: 'パスワード',
    // exhibition: '展示会',
    composition: '構成展',
    receptionId: '受付ID',
    country: '国名',
    companyName: '会社名',
    companyNameKana: '会社名（カタカナ）',
    postalCode: '郵便番号',
    prefecture: '都道府県',
    municipality: '以下住所',
    address: 'ビル名・建物名など',

    tel: '電話番号',

    familyName: '担当者1姓',
    givenName: '担当者1名',
    email: '担当者1E-Mail',
    department: '担当者1所属部署',
    position: '担当者1役職',

    familyName2: '担当者2姓',
    givenName2: '担当者2名',
    email2: '担当者2E-Mail',
    department2: '担当者2所属部署',
    position2: '担当者2役職',

    familyName3: '担当者3姓',
    givenName3: '担当者3名',
    email3: '担当者3E-Mail',
    department3: '担当者3所属部署',
    position3: '担当者3役職',
  }

  CSV.push(keys)
  CSV.push(keys.map((key) => wordMap[key]).concat(['バッジ登録枚数']))
  exhibitors.forEach((exhibitor: any) => {
    CSV.push(
      keys
        .map((key) => {
          if (key === 'exhibition') {
            return exhibitor[key].name
          } else {
            return exhibitor[key]
          }
        })
        .concat([exhibitor.badges.length])
    )
  })
  return CSV
}

export const createBadgesCSV = (
  exhibitors: Array<ExhibitorDetailFragment | ExhibitorForListFragment>
) => {
  let CSV: string[][] = []
  const keys = [
    'barcodeId',
    'displayName',
    'displayNameKana',
    'companyName',
    'companyNameKana',
    'department',
    'position',
    'postalCode',
    'prefecture',
    'municipality',
    'address',
    'email',
    'tel',
  ]

  const wordMap: { [key: string]: string } = {
    barcodeId: 'バーコードID',
    displayName: 'バッジ氏名',
    displayNameKana: 'バッジ氏名（カタカナ）',
    companyName: '会社名',
    companyNameKana: '会社名（カタカナ）',
    department: '所属部署',
    position: '役職',
    postalCode: '郵便番号',
    prefecture: '都道府県',
    municipality: '以下住所',
    address: 'ビル名・建物名など',
    email: 'E-Mail',
    tel: '電話番号',
  }
  CSV.push(['accountId'].concat(keys.map((key) => wordMap[key])))
  exhibitors.forEach((exhibitor: any) => {
    exhibitor.badges.forEach((badge: any) => {
      CSV.push([exhibitor.accountId].concat(keys.map((key) => badge[key])))
    })
  })
  return CSV
}

export const createUploadBadgesSampleCSV = () => {
  let CSV: string[][] = []
  const keys = [
    'displayName',
    'displayNameKana',
    'companyName',
    'companyNameKana',
    'department',
    'position',
    'postalCode',
    'prefecture',
    'municipality',
    'address',
    'email',
    'tel',
  ]

  const wordMap: { [key: string]: string } = {
    displayName: 'バッジ氏名',
    displayNameKana: 'バッジ氏名（カタカナ）',
    companyName: '会社名',
    companyNameKana: '会社名（カタカナ）',
    department: '所属部署',
    position: '役職',
    postalCode: '郵便番号',
    prefecture: '都道府県',
    municipality: '以下住所',
    address: 'ビル名・建物名など',
    email: 'E-Mail',
    tel: '電話番号',
  }
  const sampleValues: { [key: string]: string } = {
    displayName: '出展　太郎',
    displayNameKana: '',
    companyName: '株式会社TSO International',
    companyNameKana: '',
    department: 'IT推進室',
    position: '室長',
    postalCode: '160-0004',
    prefecture: '東京都',
    municipality: '新宿区四谷3丁目2-1',
    address: 'フロントプレイス四谷7F',
    email: 'tsoint@example.com',
    tel: '03-5363-1701',
  }
  CSV.push(keys)
  CSV.push(keys.map((key) => wordMap[key]))
  CSV.push(keys.map((key) => sampleValues[key]))
  return CSV
}
