import React from 'react'
import { Route, HashRouter as Router, Switch } from 'react-router-dom'
import AdminExhibitionDetail from './components/admin/ExhibitionDetail'
import AdminHome from './components/admin/Home'
import AdminLogin from './components/admin/Login'
import Badge from './components/Badge'
import AdminPrivateRoute from './components/common/AdminPrivateRoute'
import PrivateRoute from './components/common/PrivateRoute'
import QueryAuthRoute from './components/common/QueryAuthRoute'
import DownloadBadge from './components/DownloadBadge'
import Empty from './components/Empty'
import Explain from './components/Explain'
import Home from './components/Home'
import Login from './components/Login'
import { GraphqlProvider } from './graphql'

const App = () => {
  return (
    <>
      <Router>
        <GraphqlProvider>
          <Switch>
            <Route exact path="/admin/login" component={AdminLogin} />
            <AdminPrivateRoute path="/admin" fallbackPath="/admin/login">
              <Switch>
                <Route exact path="/admin" component={AdminHome} />
                <Route
                  exact
                  path="/admin/exhibitions/:exhibitionId"
                  component={AdminExhibitionDetail}
                />
              </Switch>
            </AdminPrivateRoute>

            <Route exact path="/" component={Empty} />
            <Route exact path="/:exhibitionId/explain" component={Explain} />
            <Route exact path="/:exhibitionId/login" component={Login} />
            <QueryAuthRoute exact path="/download/badges/:badgeId" component={DownloadBadge} />
            <QueryAuthRoute exact path="/badges/:badgeId" component={Badge} />
            <PrivateRoute
              path="/:exhibitionId"
              fallbackPath={(routeProps) => {
                const pathname = routeProps.location?.pathname
                const pathnames = pathname?.split('/')
                if (pathnames && pathnames.length >= 2) {
                  const exhibitionId = pathnames[1]
                  return `/${exhibitionId}/explain`
                }
                return '/'
              }}
            >
              <Home />
            </PrivateRoute>
          </Switch>
        </GraphqlProvider>
      </Router>
    </>
  )
}

export default App
