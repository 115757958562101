import React from 'react'
import { Link } from 'react-router-dom'
import { useExhibitionFromParams } from '../hooks'
import { setIsExplainedCookie } from '../repos/cookies'
import DefaultLayout from './common/DefaultLayout'

const sanitizeHtml = require('sanitize-html')

const ExplainPage = () => {
  setIsExplainedCookie()
  const { exhibition, exhibitionId } = useExhibitionFromParams()

  if (!exhibition) {
    return <></>
  }

  const descriptionHtml = sanitizeHtml(exhibition?.descriptionHtml || '')

  return (
    <DefaultLayout isWide exhibition={exhibition}>
      <div className="explain-wrapper">
        <div className="explain-jp">
          <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          <div className="text-center mt-3 mb-3">
            <div className="btn entry-btn">
              <Link to={`/${exhibitionId}/login`}>出展社バッジを申請する</Link>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ExplainPage
