import classNames from 'classnames'
import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import {
  BadgeModel,
  ExhibitorDetailFragment,
  useCreateBadgeMutation,
  useUpdateBadgeMutation,
} from '../../api'
import { EditBadgeForm, EditBadgeSchema } from '../../schema'
import { updateFieldsByPostalCode } from '../../utils'

Modal.setAppElement('#root')

interface Props {
  type?: string
  badge?: BadgeModel
  exhibitor: ExhibitorDetailFragment
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const EditBadgeModal = (props: Props) => {
  const [createBadgeMutation] = useCreateBadgeMutation()
  const [updateBadgeMutation] = useUpdateBadgeMutation()
  const { badge, exhibitor, type } = props

  const formMethods = useForm<EditBadgeForm>({
    validationSchema: EditBadgeSchema,
  })
  const { register, handleSubmit, errors, reset, setValue, getValues } = formMethods
  useEffect(() => {
    if (badge) {
      reset(badge as any)
    }
  }, [reset, badge])

  const copyFromExhibitor = (keyPostfix: string | undefined = '') => {
    const v = exhibitor as any
    const values = {
      displayName:
        (v['familyName' + keyPostfix] || '') + '　' + (v['givenName' + keyPostfix] || ''),
      department: v['department' + keyPostfix],
      position: v['position' + keyPostfix],
    }
    reset({ ...getValues(), ...exhibitor, ...values } as any)
  }

  const onSubmit = async (formData: EditBadgeForm) => {
    if (type === 'create') {
      await createBadgeMutation({
        variables: { input: { ...formData } },
      })
    } else {
      await updateBadgeMutation({
        variables: { id: badge!.id, input: { ...formData } },
      })
    }
    props.handleSubmit()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content update-modal">
          <div className="modal-header">
            {type === 'create' && (
              <h3 className="text-center w-100">バッジ新規登録 / New Badge Registration</h3>
            )}
            {type !== 'create' && (
              <h3 className="text-center w-100">バッジ内容編集 / Edit Badge Registration</h3>
            )}
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="btn mb-2 mr-2" onClick={() => copyFromExhibitor()}>
              出展担当者1からコピー / Copy from Exhibitor Representative 1
            </div>

            <div className="btn mb-2 mr-2" onClick={() => copyFromExhibitor('2')}>
              出展担当者2からコピー / Copy from Exhibitor Representative 2
            </div>
            <div className="btn mb-2" onClick={() => copyFromExhibitor('3')}>
              出展担当者3からコピー / Copy from Exhibitor Representative 3
            </div>

            <FormContext {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>社名 / Company Name</label>

                        <input
                          ref={register}
                          name="companyName"
                          type="text"
                          className={classNames('form-control', {
                            'is-invalid': errors.companyName,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>社名カナ / Company Name (Kana)</label>

                    <input
                      ref={register}
                      name="companyNameKana"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.companyNameKana,
                      })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>部署 / Department</label>

                        <input
                          ref={register}
                          name="department"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>役職 / Job Title</label>

                        <input
                          ref={register}
                          name="position"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>バッジ印刷氏名 / Name for Badge Printing</label>

                        <input
                          ref={register}
                          name="displayName"
                          type="text"
                          className={classNames('form-control', {
                            'is-invalid': errors.displayName,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>氏名カナ / Name (Kana)</label>

                    <input
                      ref={register}
                      name="displayNameKana"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.displayNameKana,
                      })}
                    />
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>メールアドレス / Email Address</label>

                    <input
                      ref={register}
                      name="email"
                      type="text"
                      className={classNames('form-control', { 'is-invalid': errors.email })}
                    />
                    {errors.email?.type === 'email' && (
                      <div className="invalid-feedback">
                        ※メールアドレスの形式が無効です。 / Invalid email format
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>電話番号 / Telephone Number</label>

                    <input
                      ref={register}
                      name="tel"
                      type="text"
                      className={classNames('form-control', { 'is-invalid': errors.tel })}
                    />
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>郵便番号 / Postal Code</label>

                    <input
                      ref={register}
                      name="postalCode"
                      type="text"
                      className={classNames('form-control', { 'is-invalid': errors.postalCode })}
                      onChange={(e) => {
                        updateFieldsByPostalCode(e.target.value, setValue)
                      }}
                      size={8}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>都道府県 / States</label>

                        <input
                          ref={register}
                          name="prefecture"
                          type="text"
                          className={classNames('form-control', {
                            'is-invalid': errors.prefecture,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>以下住所 / Address</label>

                        <input
                          ref={register}
                          name="municipality"
                          type="text"
                          className={classNames('form-control', {
                            'is-invalid': errors.municipality,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>ビル名 / Building Name</label>

                        <input ref={register} name="address" type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-section submit">
                  <input
                    type="submit"
                    name="submit"
                    className="btn"
                    value={type !== 'create' ? '更新 / Update' : '登録 / Register'}
                  />
                </div>
              </form>
            </FormContext>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditBadgeModal
