import classNames from 'classnames'
import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Redirect, RouteProps } from 'react-router-dom'
// import caferesContact from '../assets/images/caferes_contact.png'
// import fgdemoContact from '../assets/images/fgdemo_contact.png'
// import sportecContact from '../assets/images/sportec_contact.png'
// import tsoContact from '../assets/images/tso_contact.png'
import { useAppContext } from '../contexts/app'
import { useExhibitionFromParams } from '../hooks'
import { getIsExplainedCookie } from '../repos/cookies'
import { LoginForm, LoginSchema } from '../schema'
import DefaultLayout from './common/DefaultLayout'

const LoginPage: React.FC<RouteProps> = (props: RouteProps) => {
  const { exhibition, exhibitionId } = useExhibitionFromParams()
  const { authToken, login } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)

  const formMethods = useForm<LoginForm>({
    validationSchema: LoginSchema,
  })
  const { register, handleSubmit, errors, setError } = formMethods

  const onSubmit = async (formData: LoginForm) => {
    setIsLoading(true)

    await login(formData.accountId, formData.password)
      .catch((err) => {
        setError('accountId', 'unauthorized', '')
        setError('password', 'unauthorized', '')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!exhibition) {
    return <></>
  }

  if (authToken) {
    return <Redirect to={{ pathname: `/${exhibitionId}` }} />
  }

  if (!getIsExplainedCookie()) {
    return <Redirect to={{ pathname: `/${exhibitionId}/explain` }} />
  }

  return (
    <DefaultLayout exhibition={exhibition}>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="login-wrapper">
          <div className="form-section">
            <div className="form-group">
              <label>ログインID</label>

              <input
                ref={register}
                name="accountId"
                type="text"
                className={classNames('form-control', {
                  'is-invalid': errors.accountId?.type === 'required',
                })}
              />
              {errors.accountId && (
                <div className="invalid-feedback">※ログインIDを入力してください。</div>
              )}
            </div>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label>パスワード</label>

              <input
                ref={register}
                name="password"
                type="password"
                className={classNames('form-control', {
                  'is-invalid': errors.password?.type === 'required',
                })}
              />
              {errors.password && (
                <div className="invalid-feedback">※パスワードを入力してください。</div>
              )}
            </div>
          </div>

          {/* TODO: 仕様確認調整 */}

          {/* {exhibition === 'FGDEMO' && (
            <div className="contact text-center">
              問い合わせ先:{' '}
              <img className="fgdemo-contact" alt="contact" src={fgdemoContact} />
            </div>
          )}
          {exhibition === 'SPORTEC2023' && (
            <div className="contact text-center">
              問い合わせ先:{' '}
              <img className="sportec-contact" alt="contact" src={sportecContact} />
            </div>
          )}
          {exhibition === 'CAFERES2023' && (
            <div className="contact text-center">
              問い合わせ先:{' '}
              <img className="caferes-contact" alt="contact" src={caferesContact} />
            </div>
          )}
          {(exhibition === 'ILOJ2023' || exhibition === 'WELLNESS2023') && (
            <div className="contact text-center">
              問い合わせ先:{' '}
              <img className="tso-contact" alt="contact" src={tsoContact} />
            </div>
          )} */}

          <div className="form-section submit">
            <button
              type="submit"
              name="submit"
              className={classNames('btn', {
                'is-invalid':
                  errors.accountId?.type === 'unauthorized' &&
                  errors.password?.type === 'unauthorized',
              })}
              disabled={isLoading}
            >
              {!isLoading && <span>ログイン</span>}
              {isLoading && (
                <span>
                  ログイン中<i className="fa fa-sync-alt fa-spin"></i>
                </span>
              )}
            </button>
            <div className="invalid-feedback">
              ※認証に失敗しました。入力情報を確認してください。
            </div>
          </div>
        </form>
      </FormContext>
    </DefaultLayout>
  )
}

export default LoginPage
