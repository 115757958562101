import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ExhibitionDetailFragment } from '../../api'

const AdminSidebar = (props: { exhibition?: ExhibitionDetailFragment | null }) => {
  const exhibition = props.exhibition
  const history = useHistory()
  const matchAdminExhibitionDetailPage = useRouteMatch('/admin/exhibitions/:exhibitionId')
  return (
    <div className="sidebar">
      {matchAdminExhibitionDetailPage && (
        <div>
          <div
            className="btn btn-secondary my-3"
            onClick={() => {
              history.push('/admin')
            }}
          >
            管理トップ
          </div>
        </div>
      )}

      {exhibition && (
        <div>
          <img className="logo" alt="logo" src={exhibition.logoImageUrl} />
        </div>
      )}
    </div>
  )
}

export default AdminSidebar
