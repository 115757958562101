import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { useGetExhibitionsQuery } from '../../api'
import DefaultLayout from '../common/DefaultAdminLayout'
import ExhibitionModal from './modals/ExhibitionModal'

const AdminHomePage = () => {
  const [showModal, setShowModal] = React.useState<string | undefined>(undefined)
  const exhibitionsQuery = useGetExhibitionsQuery()

  const data = exhibitionsQuery.data

  if (!data) {
    return (
      <DefaultLayout isWide>
        <Suspense fallback={<div>...Loading</div>}>
          <div className="text-center">
            <i className="fa fa-sync-alt fa-spin"></i>
          </div>
        </Suspense>
      </DefaultLayout>
    )
  }

  const onChangeExhibition = async () => {
    setShowModal(undefined)
    await exhibitionsQuery.refetch()
  }

  return (
    <DefaultLayout isWide>
      <div className="btn-group">
        <div className="btn mr-3" onClick={() => setShowModal('new')}>
          新規展示会作成
        </div>
        <ExhibitionModal
          type="new"
          isOpen={showModal === 'new'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onChangeExhibition()}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>展示会ロゴ</th>
            <th>展示会ID</th>
            <th>展示会名称</th>
            <th>有効/無効</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.getExhibitions.map((x, i) => {
            return (
              <tr key={i}>
                <td>
                  <Link to={`/admin/exhibitions/${x.id}`}>
                    <img className="logo" alt="logo" src={x.logoImageUrl} height="25" />
                  </Link>
                </td>
                <td>{x.identifier}</td>
                <td>{x.name}</td>
                <td>{x.canLogin ? '有効' : '無効'}</td>

                <td className="tight-2">
                  <div className="btn" onClick={() => setShowModal(x.id + '-edit')}>
                    編集
                  </div>
                  <ExhibitionModal
                    type="edit"
                    exhibition={x}
                    isOpen={showModal === x.id + '-edit'}
                    handleCloseModal={() => setShowModal(undefined)}
                    handleSubmit={() => onChangeExhibition()}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultLayout>
  )
}

export default AdminHomePage
