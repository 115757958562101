import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

interface Props {
  title: string | React.ReactElement
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const ConfirmModal = (props: Props) => {
  return (
    <div className="modal confirm-modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h2>{props.title}</h2>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={props.handleSubmit}>
              OK
            </button>
            <button className="btn btn-secondary" onClick={props.handleCloseModal}>
              キャンセル / Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmModal
