import 'react-app-polyfill/ie11'
// TODO: core-jsに置き換えたほうが良さそう?
// http://var.blog.jp/archives/79457227.html
import 'babel-polyfill'
import 'array-flat-polyfill'
import { UserAgentProvider } from '@quentin-sommer/react-useragent'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/index.scss'
import 'loaders.css'
import App from './App'
import { AppContextProvider } from './contexts/app'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <AppContextProvider>
    <UserAgentProvider ua={window.navigator.userAgent}>
      <App />
    </UserAgentProvider>
  </AppContextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
