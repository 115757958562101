import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FormContext, useFieldArray, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import {
  ExhibitionDetailFragment,
  useCreateExhibitionMutation,
  useCreateUploadFileUrlMutation,
  useUpdateExhibitionMutation,
} from '../../../api'
import { ExhibitionForm, ExhibitionSchema } from '../../../schema'
import {
  defaultNewExhibitionAccountNotificationMailBodyText,
  defaultNewExhibitionBadgeNotificationMailBodyText,
  defaultNewExhibitionDescriptionHtml,
  defaultNewExhibitionRemindNotificationMailBodyText,
} from './constants'

Modal.setAppElement('#root')

interface Props {
  type: 'new' | 'edit'
  exhibition?: ExhibitionDetailFragment
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const ExhibitionModal = (props: Props) => {
  const [createUploadFileUrl] = useCreateUploadFileUrlMutation()
  const [createMutation] = useCreateExhibitionMutation()
  const [updateMutation] = useUpdateExhibitionMutation()
  const [submitError, setSubmitError] = useState('')

  const { exhibition, type } = props

  const formMethods = useForm<ExhibitionForm>({
    validationSchema: ExhibitionSchema,
    defaultValues: {
      compositions: [],
      canLogin: true,
      qrcodeNumberOfDigits: 4,
      descriptionHtml: defaultNewExhibitionDescriptionHtml,
      remindNotificationMailBodyText: defaultNewExhibitionRemindNotificationMailBodyText,
      accountNotificationMailBodyText: defaultNewExhibitionAccountNotificationMailBodyText,
      badgeNotificationMailBodyText: defaultNewExhibitionBadgeNotificationMailBodyText,
    },
  })
  const { control, register, handleSubmit, errors, reset, setValue, watch } = formMethods

  const watchedLogoImageUrl = watch('logoImageUrl')
  const watchedBadgeBaseImageUrl = watch('badgeBaseImageUrl')

  const onSubmitSuccess = () => {
    props.handleSubmit()
    setSubmitError('')
  }

  const onModalClose = () => {
    props.handleCloseModal()
    setSubmitError('')
  }

  // console.log(errors)

  const compositionsFieldArray = useFieldArray({
    control: control,
    name: 'compositions',
  })
  useEffect(() => {
    if (type === 'edit' && exhibition) {
      reset({
        ...exhibition,
        compositions: exhibition.compositions.map((x) => ({ name: x })),
        descriptionHtml: exhibition.descriptionHtml || defaultNewExhibitionDescriptionHtml,
        remindNotificationMailBodyText:
          exhibition.remindNotificationMailBodyText ||
          defaultNewExhibitionRemindNotificationMailBodyText,
        accountNotificationMailBodyText:
          exhibition.accountNotificationMailBodyText ||
          defaultNewExhibitionAccountNotificationMailBodyText,
        badgeNotificationMailBodyText:
          exhibition.badgeNotificationMailBodyText ||
          defaultNewExhibitionBadgeNotificationMailBodyText,
      })
    }
  }, [reset, exhibition, type])

  const uploadFile = async (file: File) => {
    try {
      const res = await createUploadFileUrl()
      const url = res.data?.createUploadFileUrl
      if (!url) {
        return
      }

      await fetch(url, {
        method: 'PUT',
        body: file,
      })

      return url.split('?')[0]
    } catch (e) {
      alert('画像をアップロードできませんでした')
      console.error(e)
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    if (type === 'edit' && !exhibition) return

    try {
      if (type === 'new') {
        await createMutation({
          variables: {
            input: {
              ...data,
              compositions: data.compositions?.map((x) => x.name) || [],
            },
          },
        })
      } else {
        await updateMutation({
          variables: {
            id: exhibition!.id,
            input: {
              ...data,
              compositions: data.compositions?.map((x) => x.name) || [],
            },
          },
        })
      }
      onSubmitSuccess()
    } catch (e) {
      // console.error(e)
      const apiErrors = ((e as any).graphQLErrors as { message: string }[]) || undefined
      if (
        apiErrors &&
        apiErrors.length > 0 &&
        apiErrors[0].message.startsWith('Exhibition identifier Already Exists:')
      ) {
        setSubmitError('展示会IDの展示会がすでに存在します')
      } else if (
        apiErrors &&
        apiErrors.length > 0 &&
        apiErrors[0].message.startsWith('Exhibition qrcodePrefix Already Exists:')
      ) {
        setSubmitError('QRコード番号体系（英数字）はすでに存在します')
      } else {
        setSubmitError(type === 'new' ? '作成できませんでした' : '更新できませんでした')
      }
    }
  })

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={onModalClose}
      >
        <div className="modal-content update-modal">
          <div className="modal-header">
            <button type="button" className="close" onClick={onModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FormContext {...formMethods}>
              <form onSubmit={onSubmit} className="">
                <h3>展示会情報</h3>

                <div className="form-section">
                  <div className="form-group">
                    <label>展示会ID</label>

                    <input
                      ref={register}
                      name="identifier"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.identifier,
                      })}
                    />

                    {errors.identifier && (
                      <div className="invalid-feedback">{errors.identifier.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>展示会名称</label>

                    <input
                      ref={register}
                      name="name"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.name,
                      })}
                    />
                    {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>展示会ロゴ</label>
                    <div>
                      <input
                        type="file"
                        accept="image/png"
                        onChange={(e) => {
                          if (!e.target.files) return
                          const file = e.target.files[0]
                          uploadFile(file).then((url) => {
                            setValue('logoImageUrl', url, true)
                          })
                        }}
                      />

                      <input
                        ref={register}
                        name="logoImageUrl"
                        type="hidden"
                        className={classNames('form-control', {
                          'is-invalid': errors.logoImageUrl,
                        })}
                      />
                      {errors.logoImageUrl && (
                        <div className="invalid-feedback">{errors.logoImageUrl.message}</div>
                      )}
                    </div>
                    <div className="m-3">
                      {watchedLogoImageUrl && (
                        <>
                          <img src={watchedLogoImageUrl} alt="" width="100" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <div className="form-group">
                    <label>出展社バッジベース画像</label>
                    <div>
                      <input
                        type="file"
                        accept="image/png"
                        onChange={(e) => {
                          if (!e.target.files) return
                          const file = e.target.files[0]
                          uploadFile(file).then((url) => {
                            setValue('badgeBaseImageUrl', url, true)
                          })
                        }}
                      />

                      <input
                        ref={register}
                        name="badgeBaseImageUrl"
                        type="hidden"
                        className={classNames('form-control', {
                          'is-invalid': errors.badgeBaseImageUrl,
                        })}
                      />
                      {errors.badgeBaseImageUrl && (
                        <div className="invalid-feedback">{errors.badgeBaseImageUrl.message}</div>
                      )}
                    </div>
                    <div className="m-3">
                      {watchedBadgeBaseImageUrl && (
                        <>
                          <img src={watchedBadgeBaseImageUrl} alt="" width="100" />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>QRコード番号体系（英数字）</label>

                    <input
                      ref={register}
                      name="qrcodePrefix"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.qrcodePrefix,
                      })}
                    />
                    {errors.qrcodePrefix && (
                      <div className="invalid-feedback">{errors.qrcodePrefix.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>QRコード番号体系（数字桁数）</label>

                    <input
                      ref={register}
                      name="qrcodeNumberOfDigits"
                      min={4}
                      max={12}
                      type="number"
                      className={classNames('form-control', {
                        'is-invalid': errors.qrcodeNumberOfDigits,
                      })}
                    />
                    {errors.qrcodeNumberOfDigits && (
                      <div className="invalid-feedback">{errors.qrcodeNumberOfDigits.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>構成展一覧</label>
                    <ul className="list-group">
                      {compositionsFieldArray.fields.map((x, index) => (
                        <li className="list-group-item d-flex" key={index}>
                          <div className="p-0 m-0 flex-grow-1">
                            <input
                              ref={register}
                              name={`compositions.${index}.name`}
                              type="text"
                              className={classNames('form-control', {
                                'is-invalid':
                                  errors.compositions && errors.compositions[index]?.name,
                              })}
                            />
                            {errors.compositions && errors.compositions[index]?.name && (
                              <div className="invalid-feedback">
                                {errors.compositions[index]?.name?.message}
                              </div>
                            )}
                          </div>
                          <button
                            className="btn-danger m-2"
                            onClick={(e) => {
                              e.preventDefault()
                              compositionsFieldArray.remove(index)
                            }}
                          >
                            削除
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="btn btn-secondary btn-sm mt-3"
                      onClick={(e) => {
                        e.preventDefault()
                        compositionsFieldArray.append({
                          name: '',
                        })
                      }}
                    >
                      構成展を追加
                    </button>{' '}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>出展社ログイン</label>

                    <div className="form-check">
                      <input
                        ref={register}
                        name="canLogin"
                        type="checkbox"
                        className={classNames('form-check-input', {
                          'is-invalid': errors.canLogin,
                        })}
                        id="newExhibitionFormCanLogin"
                      />

                      <label className="form-check-label" htmlFor="newExhibitionFormCanLogin">
                        出展社ログイン 有効/無効
                      </label>
                    </div>

                    {errors.canLogin && (
                      <div className="invalid-feedback">{errors.canLogin.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>説明ページ（Html）</label>

                    <textarea
                      ref={register}
                      name="descriptionHtml"
                      className={classNames('form-control', {
                        'is-invalid': errors.descriptionHtml,
                      })}
                      rows={5}
                    />
                    {errors.descriptionHtml && (
                      <div className="invalid-feedback">{errors.descriptionHtml.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>アカウント通知メール本文</label>

                    <textarea
                      ref={register}
                      name="accountNotificationMailBodyText"
                      className={classNames('form-control', {
                        'is-invalid': errors.accountNotificationMailBodyText,
                      })}
                      rows={5}
                    />
                    {errors.accountNotificationMailBodyText && (
                      <div className="invalid-feedback">
                        {errors.accountNotificationMailBodyText.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>リマインド通知メール本文</label>

                    <textarea
                      ref={register}
                      name="remindNotificationMailBodyText"
                      className={classNames('form-control', {
                        'is-invalid': errors.remindNotificationMailBodyText,
                      })}
                      rows={5}
                    />
                    {errors.remindNotificationMailBodyText && (
                      <div className="invalid-feedback">
                        {errors.remindNotificationMailBodyText.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>バッジ通知メール本文</label>

                    <textarea
                      ref={register}
                      name="badgeNotificationMailBodyText"
                      className={classNames('form-control', {
                        'is-invalid': errors.badgeNotificationMailBodyText,
                      })}
                      rows={5}
                    />
                    {errors.badgeNotificationMailBodyText && (
                      <div className="invalid-feedback">
                        {errors.badgeNotificationMailBodyText.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-section submit">
                  {submitError && <div className="alert alert-danger">{submitError}</div>}
                  <input
                    type="submit"
                    name="submit"
                    className="btn"
                    value={type === 'new' ? '作成' : '更新'}
                  />
                </div>
              </form>
            </FormContext>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ExhibitionModal
