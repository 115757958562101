import * as yup from 'yup'

type Nullable<T> = { [P in keyof T]: T[P] | null }

export type LoginForm = {
  accountId: string
  password: string
}

export const LoginSchema = yup.object().shape<LoginForm>({
  accountId: yup.string().required(),
  password: yup.string().required(),
})

export type EditExhibitorForm = {
  type: string
  accountId: string
  password: string
  // exhibitionId: string
  composition: string
  receptionId: string
  boothsCount: number
  country: string
  companyName: string
  companyNameKana: string
  postalCode: string
  prefecture: string
  municipality: string
  address: string
  tel: string

  familyName: string
  givenName: string
  email: string
  department: string
  position: string
  familyName2: string
  givenName2: string
  email2: string
  department2: string
  position2: string
  familyName3: string
  givenName3: string
  email3: string
  department3: string
  position3: string
}

export const EditExhibitorSchema = yup.object().shape<EditExhibitorForm>({
  type: yup.string().required(),
  accountId: yup.string().required(),
  password: yup.string().required(),
  // exhibitionId: yup.string().required(),
  composition: yup.string(),
  receptionId: yup.string(),
  boothsCount: yup.number(),
  country: yup.string(),
  companyName: yup.string().when('type', {
    is: (type) => type === 'new' || type === 'edit',
    then: yup.string().required(),
  }),
  companyNameKana: yup.string(),
  postalCode: yup.string(),
  prefecture: yup.string(),
  municipality: yup.string(),
  address: yup.string(),
  tel: yup.string(),
  familyName: yup.string(),
  givenName: yup.string(),
  email: yup.string().email(),
  department: yup.string(),
  position: yup.string(),

  familyName2: yup.string(),
  givenName2: yup.string(),
  email2: yup.string().email(),
  department2: yup.string(),
  position2: yup.string(),

  familyName3: yup.string(),
  givenName3: yup.string(),
  email3: yup.string().email(),
  department3: yup.string(),
  position3: yup.string(),
})

export type EditExhibitorPasswordForm = {
  password: string
}

export const EditExhibitorPasswordSchema = yup.object().shape<EditExhibitorPasswordForm>({
  password: yup.string().required(),
})

export type EditBadgeForm = {
  displayName: string
  displayNameKana: string
  companyName: string
  companyNameKana: string
  department: string
  position: string
  postalCode: string
  prefecture: string
  municipality: string
  address: string
  email: string
  tel: string
}

export const EditBadgeSchema = yup.object().shape<EditBadgeForm>({
  displayName: yup.string().required(),
  displayNameKana: yup.string(),
  companyName: yup.string().required(),
  companyNameKana: yup.string(),
  department: yup.string(),
  position: yup.string(),
  postalCode: yup.string(),
  prefecture: yup.string(),
  municipality: yup.string(),
  address: yup.string(),
  email: yup.string().email().required(),
  tel: yup.string().required(),
})

export type MailForm = {
  message: string
}

export const MailSchema = yup.object().shape<MailForm>({
  message: yup.string().required(),
})

export type ExhibitionForm = {
  identifier: string
  name: string
  logoImageUrl: string
  badgeBaseImageUrl: string
  descriptionHtml: string
  qrcodePrefix: string
  qrcodeNumberOfDigits: number
  compositions: {
    name: string
  }[]
  canLogin: boolean
  accountNotificationMailBodyText: string
  remindNotificationMailBodyText: string
  badgeNotificationMailBodyText: string
}

export const ExhibitionSchema = yup.object().shape<ExhibitionForm>({
  identifier: yup
    .string()
    .required('※展示会IDを入力してください。')
    .min(6, '※6文字以上で設定可能です')
    .max(20, '※20文字以内で設定可能です')
    .matches(/^[a-zA-Z0-9]+$/, '※英数字のみ設定可能です'),
  name: yup.string().required('※展示会名称を入力してください。'),
  logoImageUrl: yup.string().required('※展示会ロゴ画像を選択してください。'),
  badgeBaseImageUrl: yup.string().required('※出展社バッジベース画像を選択してください。'),
  descriptionHtml: yup.string().required('※説明ページを入力してください。'),
  qrcodePrefix: yup
    .string()
    .required('※QRコード番号体系（英数字）を入力してください。')
    .max(8, '※8文字以内で設定可能です')
    .matches(/^[a-zA-Z0-9]+$/, '※英数字のみ設定可能です'),
  qrcodeNumberOfDigits: yup
    .number()
    .required('')
    .min(4, '')
    .max(12, '')
    .transform((value, originalValue) => (originalValue === '' ? 0 : value)),
  compositions: yup.array().of<{ name: string }>(
    yup.object().shape({
      name: yup.string().required('※構成展名称を入力してください。'),
    })
  ),
  canLogin: yup.boolean(),
  accountNotificationMailBodyText: yup
    .string()
    .required('※アカウント通知メール本文テキストを入力してください。'),
  remindNotificationMailBodyText: yup
    .string()
    .required('※リマインド通知メール本文テキストを入力してください。'),
  badgeNotificationMailBodyText: yup
    .string()
    .required('※バッジ通知メール本文テキストを入力してください。'),
})
