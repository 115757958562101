import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BadgeModel, useCurrentUserQuery } from '../api'
import { useAppContext } from '../contexts/app'
import DefaultLayout from './common/DefaultLayout'

const DownloadBadgePage = () => {
  const { badgeId } = useParams<{ badgeId: string }>()
  const { downloadBadge } = useAppContext()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  const currentUser = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  })

  const exhibitor = currentUser.data?.currentUser
  const exhibition = exhibitor?.exhibition

  if (!exhibitor || !exhibition) {
    return <></>
  }

  const badge = exhibitor.badges.find((badge) => badge.id === badgeId)
  function download() {
    setIsDownloading(true)
    downloadBadge(badge as BadgeModel).finally(() => {
      setIsDownloaded(true)
      setIsDownloading(false)
    })
  }
  if (!isDownloading && !isDownloaded) {
    download()
  }
  return (
    <DefaultLayout isDownload exhibition={exhibition}>
      <div>自動でダウンロードが始まります。</div>
      <div>The download will start automatically.</div>
      {!isDownloading && (
        <div className="btn mt-2" onClick={() => download()}>
          ダウンロード / Download
        </div>
      )}
      {isDownloading && (
        <div className="btn loading mt-2">
          ダウンロード中 / Downloading&nbsp;<i className="fa fa-sync-alt fa-spin"></i>
        </div>
      )}
    </DefaultLayout>
  )
}

export default DownloadBadgePage
