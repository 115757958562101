import React, { useEffect, useMemo } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { ExhibitionDetailFragment, useSendBadgeNotificationMailMutation } from '../../api'
import { MailForm, MailSchema } from '../../schema'
import { formatDate, genBadgeNotificationMailMessage } from '../../utils'

Modal.setAppElement('#root')

interface Props {
  title: string
  exhibition: ExhibitionDetailFragment
  badgeId: string
  targetEmail: string
  notifiedAt?: string | null
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const MailNotificationModal = (props: Props) => {
  const [sendBadgeNotificationMailMutation] = useSendBadgeNotificationMailMutation()
  const formMethods = useForm<MailForm>({
    validationSchema: MailSchema,
  })
  const { exhibition, badgeId } = props
  const { register, handleSubmit, errors, reset } = formMethods

  const canSend = !!props.targetEmail

  const message = useMemo(() => {
    return genBadgeNotificationMailMessage({
      exhibition,
      targetEmail: props.targetEmail,
      badgeId,
    })
  }, [badgeId, exhibition, props.targetEmail])

  useEffect(() => {
    reset({ message })
  }, [message, reset])

  const onSubmit = async (formData: MailForm) => {
    const mail = {
      to: props.targetEmail,
      subject: '【返信不可】 バッジ発行通知 / Badge Issuance Notification',
      message: formData.message,
    }

    await sendBadgeNotificationMailMutation({
      variables: { input: { ...mail }, id: props.badgeId },
    })

    props.handleSubmit()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className="modal-content mail-modal">
            <div className="modal-header">
              <h3 className="text-center w-100">{props.title}</h3>
              <button type="button" className="close" onClick={props.handleCloseModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="is-notified-group mb-4">
                <div>バッジ発行通知 / Badge Issuance Notification</div>
                <div>
                  {props.notifiedAt ? (
                    <span>
                      <i className="checked mr-2" />
                      通知済 / Notified {formatDate(props.notifiedAt)}
                    </span>
                  ) : (
                    '未 / Unsent'
                  )}
                </div>
              </div>
              {canSend && (
                <div>
                  <div className="mb-2">
                    下記内容を {props.targetEmail} にメール通知します。
                    <br />
                    よろしければ「通知」ボタンを押してください。
                    <br />
                    The following content will be emailed to {props.targetEmail}.<br />
                    If this is fine, please press the "Notify" button.
                  </div>
                  <textarea
                    ref={register}
                    name="message"
                    rows={message.split('\n').length}
                    className="form-control"
                  />
                  {errors.message && (
                    <div className="invalid-feedback">※メール内容を入力してください。</div>
                  )}
                </div>
              )}
              {!canSend && (
                <div>送信先メールアドレスが未設定です。 / Recipient email address is not set.</div>
              )}
            </div>
            <div className="modal-footer">
              {canSend && (
                <input type="submit" name="submit" className="btn mr-4" value="通知 / Notify" />
              )}
              <button className="btn btn-secondary" onClick={props.handleCloseModal}>
                キャンセル / Cancel
              </button>
            </div>
          </form>
        </FormContext>
      </Modal>
    </div>
  )
}

export default MailNotificationModal
