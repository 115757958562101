import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BadgeInput = {
  displayName: Scalars['String'];
  displayNameKana?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyNameKana?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
};

export type BadgeModel = {
   __typename?: 'BadgeModel';
  id: Scalars['ID'];
  barcodeId: Scalars['String'];
  displayName: Scalars['String'];
  displayNameKana?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyNameKana?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
  badgeNotifiedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CurrentExhibitorInput = {
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  familyName2?: Maybe<Scalars['String']>;
  givenName2?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  department2?: Maybe<Scalars['String']>;
  position2?: Maybe<Scalars['String']>;
  familyName3?: Maybe<Scalars['String']>;
  givenName3?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  department3?: Maybe<Scalars['String']>;
  position3?: Maybe<Scalars['String']>;
};

export type ExhibitionInput = {
  identifier: Scalars['String'];
  name: Scalars['String'];
  logoImageUrl: Scalars['String'];
  badgeBaseImageUrl: Scalars['String'];
  compositions: Array<Scalars['String']>;
  accountNotificationMailBodyText: Scalars['String'];
  remindNotificationMailBodyText: Scalars['String'];
  badgeNotificationMailBodyText: Scalars['String'];
  descriptionHtml: Scalars['String'];
  qrcodePrefix: Scalars['String'];
  qrcodeNumberOfDigits: Scalars['Int'];
  canLogin: Scalars['Boolean'];
};

export type ExhibitionModel = {
   __typename?: 'ExhibitionModel';
  id: Scalars['ID'];
  identifier: Scalars['ID'];
  name: Scalars['String'];
  logoImageUrl: Scalars['String'];
  badgeBaseImageUrl: Scalars['String'];
  compositions: Array<Scalars['String']>;
  accountNotificationMailBodyText: Scalars['String'];
  remindNotificationMailBodyText: Scalars['String'];
  badgeNotificationMailBodyText: Scalars['String'];
  descriptionHtml: Scalars['String'];
  qrcodePrefix: Scalars['String'];
  qrcodeNumberOfDigits: Scalars['Int'];
  canLogin: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ExhibitorInput = {
  accountId: Scalars['String'];
  password: Scalars['String'];
  exhibitionId: Scalars['String'];
  composition?: Maybe<Scalars['String']>;
  receptionId?: Maybe<Scalars['String']>;
  boothsCount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameKana?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  familyName2?: Maybe<Scalars['String']>;
  givenName2?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  department2?: Maybe<Scalars['String']>;
  position2?: Maybe<Scalars['String']>;
  familyName3?: Maybe<Scalars['String']>;
  givenName3?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  department3?: Maybe<Scalars['String']>;
  position3?: Maybe<Scalars['String']>;
};

export type ExhibitorModel = {
   __typename?: 'ExhibitorModel';
  id: Scalars['ID'];
  accountId: Scalars['String'];
  password: Scalars['String'];
  exhibitionId: Scalars['String'];
  exhibition: ExhibitionModel;
  composition?: Maybe<Scalars['String']>;
  receptionId?: Maybe<Scalars['String']>;
  boothsCount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameKana?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  badges: Array<BadgeModel>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  familyName2?: Maybe<Scalars['String']>;
  givenName2?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  department2?: Maybe<Scalars['String']>;
  position2?: Maybe<Scalars['String']>;
  familyName3?: Maybe<Scalars['String']>;
  givenName3?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  department3?: Maybe<Scalars['String']>;
  position3?: Maybe<Scalars['String']>;
  accountNotifiedAt?: Maybe<Scalars['String']>;
  remindNotifiedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type MailInput = {
  to: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  /** ログイン出展社パスワードを更新 */
  updateCurrentUserPassword: ExhibitorModel;
  /** ログイン出展社を更新 */
  updateCurrentExhibitor: ExhibitorModel;
  /** 出展社 代理ログイン */
  loginAsExhibitor: Scalars['String'];
  /** ファイルアップロード先URLの取得 */
  createUploadFileUrl: Scalars['String'];
  /** 出展社を作成する */
  createExhibitor: ExhibitorModel;
  /** idの出展社を更新 */
  updateExhibitor: ExhibitorModel;
  /** idの出展社を削除 */
  deleteExhibitor: Scalars['String'];
  /** アカウント情報通知送信 */
  sendAccountNotificationMail: Scalars['Boolean'];
  /** リマインド通知送信 */
  sendRemindNotificationMail: Scalars['Boolean'];
  /** 展示会を作成する */
  createExhibition: ExhibitionModel;
  /** idの展示会を更新 */
  updateExhibition: ExhibitionModel;
  /** idの展示会を削除 */
  deleteExhibition: Scalars['String'];
  /** バッジを作成する */
  createBadge: BadgeModel;
  /** idのバッジを更新 */
  updateBadge: BadgeModel;
  /** idのバッジを削除 */
  deleteBadge: Scalars['String'];
  /** バッジ通知送信 */
  sendBadgeNotificationMail: Scalars['Boolean'];
};


export type MutationUpdateCurrentUserPasswordArgs = {
  password: Scalars['String'];
};


export type MutationUpdateCurrentExhibitorArgs = {
  input: CurrentExhibitorInput;
};


export type MutationLoginAsExhibitorArgs = {
  exhibitorId: Scalars['String'];
};


export type MutationCreateExhibitorArgs = {
  input: ExhibitorInput;
};


export type MutationUpdateExhibitorArgs = {
  input: ExhibitorInput;
  id: Scalars['ID'];
};


export type MutationDeleteExhibitorArgs = {
  id: Scalars['ID'];
};


export type MutationSendAccountNotificationMailArgs = {
  input: MailInput;
  id: Scalars['ID'];
};


export type MutationSendRemindNotificationMailArgs = {
  input: MailInput;
  id: Scalars['ID'];
};


export type MutationCreateExhibitionArgs = {
  input: ExhibitionInput;
};


export type MutationUpdateExhibitionArgs = {
  input: ExhibitionInput;
  id: Scalars['ID'];
};


export type MutationDeleteExhibitionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBadgeArgs = {
  input: BadgeInput;
};


export type MutationUpdateBadgeArgs = {
  input: BadgeInput;
  id: Scalars['ID'];
};


export type MutationDeleteBadgeArgs = {
  id: Scalars['ID'];
};


export type MutationSendBadgeNotificationMailArgs = {
  input: MailInput;
  id: Scalars['ID'];
};

export type Query = {
   __typename?: 'Query';
  /** ログイン出展社 */
  currentUser: ExhibitorModel;
  /** identifierの展示会を取得 */
  getExhibitionByIdentifier: ExhibitionModel;
  /** idの出展社を取得 */
  getExhibitorById: ExhibitorModel;
  /** 出展社一覧 */
  getExhibitors: Array<ExhibitorModel>;
  /** 展示会一覧 */
  getExhibitions: Array<ExhibitionModel>;
  /** idの展示会を取得 */
  getExhibitionById: ExhibitionModel;
};


export type QueryGetExhibitionByIdentifierArgs = {
  identifier: Scalars['String'];
};


export type QueryGetExhibitorByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetExhibitorsArgs = {
  exhibitionId?: Maybe<Scalars['String']>;
};


export type QueryGetExhibitionByIdArgs = {
  id: Scalars['ID'];
};

export type BadgeDetailFragment = (
  { __typename?: 'BadgeModel' }
  & Pick<BadgeModel, 'id' | 'barcodeId' | 'displayName' | 'displayNameKana' | 'companyName' | 'companyNameKana' | 'department' | 'position' | 'postalCode' | 'prefecture' | 'municipality' | 'address' | 'email' | 'tel' | 'badgeNotifiedAt' | 'createdAt' | 'updatedAt'>
);

export type ExhibitionForListFragment = (
  { __typename?: 'ExhibitionModel' }
  & Pick<ExhibitionModel, 'id' | 'identifier' | 'name' | 'logoImageUrl' | 'badgeBaseImageUrl' | 'createdAt' | 'updatedAt' | 'canLogin' | 'compositions' | 'qrcodePrefix' | 'qrcodeNumberOfDigits'>
);

export type ExhibitionDetailFragment = (
  { __typename?: 'ExhibitionModel' }
  & Pick<ExhibitionModel, 'id' | 'identifier' | 'name' | 'logoImageUrl' | 'badgeBaseImageUrl' | 'createdAt' | 'updatedAt' | 'canLogin' | 'compositions' | 'descriptionHtml' | 'qrcodePrefix' | 'qrcodeNumberOfDigits' | 'accountNotificationMailBodyText' | 'remindNotificationMailBodyText' | 'badgeNotificationMailBodyText'>
);

export type ExhibitorForListFragment = (
  { __typename?: 'ExhibitorModel' }
  & Pick<ExhibitorModel, 'id' | 'accountId' | 'exhibitionId' | 'composition' | 'password' | 'receptionId' | 'boothsCount' | 'country' | 'companyName' | 'companyNameKana' | 'postalCode' | 'prefecture' | 'municipality' | 'address' | 'familyName' | 'givenName' | 'email' | 'department' | 'position' | 'familyName2' | 'givenName2' | 'email2' | 'department2' | 'position2' | 'familyName3' | 'givenName3' | 'email3' | 'department3' | 'position3' | 'tel' | 'accountNotifiedAt' | 'remindNotifiedAt' | 'createdAt' | 'updatedAt'>
  & { badges: Array<(
    { __typename?: 'BadgeModel' }
    & BadgeDetailFragment
  )>, exhibition: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionForListFragment
  ) }
);

export type ExhibitorDetailFragment = (
  { __typename?: 'ExhibitorModel' }
  & Pick<ExhibitorModel, 'id' | 'accountId' | 'exhibitionId' | 'composition' | 'password' | 'receptionId' | 'boothsCount' | 'country' | 'companyName' | 'companyNameKana' | 'postalCode' | 'prefecture' | 'municipality' | 'address' | 'familyName' | 'givenName' | 'email' | 'department' | 'position' | 'familyName2' | 'givenName2' | 'email2' | 'department2' | 'position2' | 'familyName3' | 'givenName3' | 'email3' | 'department3' | 'position3' | 'tel' | 'accountNotifiedAt' | 'remindNotifiedAt' | 'createdAt' | 'updatedAt'>
  & { badges: Array<(
    { __typename?: 'BadgeModel' }
    & BadgeDetailFragment
  )>, exhibition: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  ) }
);

export type CreateBadgeMutationVariables = {
  input: BadgeInput;
};


export type CreateBadgeMutation = (
  { __typename?: 'Mutation' }
  & { createBadge: (
    { __typename?: 'BadgeModel' }
    & BadgeDetailFragment
  ) }
);

export type CreateExhibitionMutationVariables = {
  input: ExhibitionInput;
};


export type CreateExhibitionMutation = (
  { __typename?: 'Mutation' }
  & { createExhibition: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  ) }
);

export type CreateExhibitorMutationVariables = {
  input: ExhibitorInput;
};


export type CreateExhibitorMutation = (
  { __typename?: 'Mutation' }
  & { createExhibitor: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type CreateUploadFileUrlMutationVariables = {};


export type CreateUploadFileUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUploadFileUrl'>
);

export type DeleteBadgeMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteBadgeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBadge'>
);

export type DeleteExhibitionMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteExhibitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExhibition'>
);

export type DeleteExhibitorMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteExhibitorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExhibitor'>
);

export type LoginAsExhibitorMutationVariables = {
  exhibitorId: Scalars['String'];
};


export type LoginAsExhibitorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'loginAsExhibitor'>
);

export type SendAccountNotificationMailMutationVariables = {
  id: Scalars['ID'];
  input: MailInput;
};


export type SendAccountNotificationMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendAccountNotificationMail'>
);

export type SendBadgeNotificationMailMutationVariables = {
  id: Scalars['ID'];
  input: MailInput;
};


export type SendBadgeNotificationMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendBadgeNotificationMail'>
);

export type SendRemindNotificationMailMutationVariables = {
  id: Scalars['ID'];
  input: MailInput;
};


export type SendRemindNotificationMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendRemindNotificationMail'>
);

export type UpdateBadgeMutationVariables = {
  id: Scalars['ID'];
  input: BadgeInput;
};


export type UpdateBadgeMutation = (
  { __typename?: 'Mutation' }
  & { updateBadge: (
    { __typename?: 'BadgeModel' }
    & BadgeDetailFragment
  ) }
);

export type UpdateExhibitionMutationVariables = {
  id: Scalars['ID'];
  input: ExhibitionInput;
};


export type UpdateExhibitionMutation = (
  { __typename?: 'Mutation' }
  & { updateExhibition: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  ) }
);

export type UpdateCurrentUserPasswordMutationVariables = {
  password: Scalars['String'];
};


export type UpdateCurrentUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUserPassword: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type UpdateExhibitorMutationVariables = {
  id: Scalars['ID'];
  input: ExhibitorInput;
};


export type UpdateExhibitorMutation = (
  { __typename?: 'Mutation' }
  & { updateExhibitor: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type UpdateCurrentExhibitorMutationVariables = {
  input: CurrentExhibitorInput;
};


export type UpdateCurrentExhibitorMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentExhibitor: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type GetExhibitionByIdQueryVariables = {
  id: Scalars['ID'];
};


export type GetExhibitionByIdQuery = (
  { __typename?: 'Query' }
  & { getExhibitionById: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  ) }
);

export type GetExhibitionByIdentifierQueryVariables = {
  identifier: Scalars['String'];
};


export type GetExhibitionByIdentifierQuery = (
  { __typename?: 'Query' }
  & { getExhibitionByIdentifier: (
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  ) }
);

export type GetExhibitionsQueryVariables = {};


export type GetExhibitionsQuery = (
  { __typename?: 'Query' }
  & { getExhibitions: Array<(
    { __typename?: 'ExhibitionModel' }
    & ExhibitionDetailFragment
  )> }
);

export type GetExhibitorByIdQueryVariables = {
  id: Scalars['ID'];
};


export type GetExhibitorByIdQuery = (
  { __typename?: 'Query' }
  & { getExhibitorById: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type CurrentUserQueryVariables = {};


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'ExhibitorModel' }
    & ExhibitorDetailFragment
  ) }
);

export type GetExhibitorsQueryVariables = {
  exhibitionId?: Maybe<Scalars['String']>;
};


export type GetExhibitorsQuery = (
  { __typename?: 'Query' }
  & { getExhibitors: Array<(
    { __typename?: 'ExhibitorModel' }
    & ExhibitorForListFragment
  )> }
);

export const BadgeDetailFragmentDoc = gql`
    fragment BadgeDetail on BadgeModel {
  id
  barcodeId
  displayName
  displayNameKana
  companyName
  companyNameKana
  department
  position
  postalCode
  prefecture
  municipality
  address
  email
  tel
  badgeNotifiedAt
  createdAt
  updatedAt
}
    `;
export const ExhibitionForListFragmentDoc = gql`
    fragment ExhibitionForList on ExhibitionModel {
  id
  identifier
  name
  logoImageUrl
  badgeBaseImageUrl
  createdAt
  updatedAt
  canLogin
  compositions
  qrcodePrefix
  qrcodeNumberOfDigits
}
    `;
export const ExhibitorForListFragmentDoc = gql`
    fragment ExhibitorForList on ExhibitorModel {
  id
  accountId
  exhibitionId
  composition
  password
  receptionId
  boothsCount
  country
  companyName
  companyNameKana
  postalCode
  prefecture
  municipality
  address
  familyName
  givenName
  email
  department
  position
  familyName2
  givenName2
  email2
  department2
  position2
  familyName3
  givenName3
  email3
  department3
  position3
  tel
  badges {
    ...BadgeDetail
  }
  exhibition {
    ...ExhibitionForList
  }
  accountNotifiedAt
  remindNotifiedAt
  createdAt
  updatedAt
}
    ${BadgeDetailFragmentDoc}
${ExhibitionForListFragmentDoc}`;
export const ExhibitionDetailFragmentDoc = gql`
    fragment ExhibitionDetail on ExhibitionModel {
  id
  identifier
  name
  logoImageUrl
  badgeBaseImageUrl
  createdAt
  updatedAt
  canLogin
  compositions
  descriptionHtml
  qrcodePrefix
  qrcodeNumberOfDigits
  accountNotificationMailBodyText
  remindNotificationMailBodyText
  badgeNotificationMailBodyText
}
    `;
export const ExhibitorDetailFragmentDoc = gql`
    fragment ExhibitorDetail on ExhibitorModel {
  id
  accountId
  exhibitionId
  composition
  password
  receptionId
  boothsCount
  country
  companyName
  companyNameKana
  postalCode
  prefecture
  municipality
  address
  familyName
  givenName
  email
  department
  position
  familyName2
  givenName2
  email2
  department2
  position2
  familyName3
  givenName3
  email3
  department3
  position3
  tel
  badges {
    ...BadgeDetail
  }
  exhibition {
    ...ExhibitionDetail
  }
  accountNotifiedAt
  remindNotifiedAt
  createdAt
  updatedAt
}
    ${BadgeDetailFragmentDoc}
${ExhibitionDetailFragmentDoc}`;
export const CreateBadgeDocument = gql`
    mutation createBadge($input: BadgeInput!) {
  createBadge(input: $input) {
    ...BadgeDetail
  }
}
    ${BadgeDetailFragmentDoc}`;
export type CreateBadgeMutationFn = ApolloReactCommon.MutationFunction<CreateBadgeMutation, CreateBadgeMutationVariables>;

/**
 * __useCreateBadgeMutation__
 *
 * To run a mutation, you first call `useCreateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadgeMutation, { data, loading, error }] = useCreateBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBadgeMutation, CreateBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBadgeMutation, CreateBadgeMutationVariables>(CreateBadgeDocument, baseOptions);
      }
export type CreateBadgeMutationHookResult = ReturnType<typeof useCreateBadgeMutation>;
export type CreateBadgeMutationResult = ApolloReactCommon.MutationResult<CreateBadgeMutation>;
export type CreateBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBadgeMutation, CreateBadgeMutationVariables>;
export const CreateExhibitionDocument = gql`
    mutation createExhibition($input: ExhibitionInput!) {
  createExhibition(input: $input) {
    ...ExhibitionDetail
  }
}
    ${ExhibitionDetailFragmentDoc}`;
export type CreateExhibitionMutationFn = ApolloReactCommon.MutationFunction<CreateExhibitionMutation, CreateExhibitionMutationVariables>;

/**
 * __useCreateExhibitionMutation__
 *
 * To run a mutation, you first call `useCreateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExhibitionMutation, { data, loading, error }] = useCreateExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExhibitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExhibitionMutation, CreateExhibitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExhibitionMutation, CreateExhibitionMutationVariables>(CreateExhibitionDocument, baseOptions);
      }
export type CreateExhibitionMutationHookResult = ReturnType<typeof useCreateExhibitionMutation>;
export type CreateExhibitionMutationResult = ApolloReactCommon.MutationResult<CreateExhibitionMutation>;
export type CreateExhibitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExhibitionMutation, CreateExhibitionMutationVariables>;
export const CreateExhibitorDocument = gql`
    mutation createExhibitor($input: ExhibitorInput!) {
  createExhibitor(input: $input) {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;
export type CreateExhibitorMutationFn = ApolloReactCommon.MutationFunction<CreateExhibitorMutation, CreateExhibitorMutationVariables>;

/**
 * __useCreateExhibitorMutation__
 *
 * To run a mutation, you first call `useCreateExhibitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExhibitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExhibitorMutation, { data, loading, error }] = useCreateExhibitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExhibitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExhibitorMutation, CreateExhibitorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExhibitorMutation, CreateExhibitorMutationVariables>(CreateExhibitorDocument, baseOptions);
      }
export type CreateExhibitorMutationHookResult = ReturnType<typeof useCreateExhibitorMutation>;
export type CreateExhibitorMutationResult = ApolloReactCommon.MutationResult<CreateExhibitorMutation>;
export type CreateExhibitorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExhibitorMutation, CreateExhibitorMutationVariables>;
export const CreateUploadFileUrlDocument = gql`
    mutation createUploadFileUrl {
  createUploadFileUrl
}
    `;
export type CreateUploadFileUrlMutationFn = ApolloReactCommon.MutationFunction<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>;

/**
 * __useCreateUploadFileUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadFileUrlMutation, { data, loading, error }] = useCreateUploadFileUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUploadFileUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>(CreateUploadFileUrlDocument, baseOptions);
      }
export type CreateUploadFileUrlMutationHookResult = ReturnType<typeof useCreateUploadFileUrlMutation>;
export type CreateUploadFileUrlMutationResult = ApolloReactCommon.MutationResult<CreateUploadFileUrlMutation>;
export type CreateUploadFileUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>;
export const DeleteBadgeDocument = gql`
    mutation deleteBadge($id: ID!) {
  deleteBadge(id: $id)
}
    `;
export type DeleteBadgeMutationFn = ApolloReactCommon.MutationFunction<DeleteBadgeMutation, DeleteBadgeMutationVariables>;

/**
 * __useDeleteBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBadgeMutation, { data, loading, error }] = useDeleteBadgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBadgeMutation, DeleteBadgeMutationVariables>(DeleteBadgeDocument, baseOptions);
      }
export type DeleteBadgeMutationHookResult = ReturnType<typeof useDeleteBadgeMutation>;
export type DeleteBadgeMutationResult = ApolloReactCommon.MutationResult<DeleteBadgeMutation>;
export type DeleteBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>;
export const DeleteExhibitionDocument = gql`
    mutation deleteExhibition($id: ID!) {
  deleteExhibition(id: $id)
}
    `;
export type DeleteExhibitionMutationFn = ApolloReactCommon.MutationFunction<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>;

/**
 * __useDeleteExhibitionMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitionMutation, { data, loading, error }] = useDeleteExhibitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExhibitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>(DeleteExhibitionDocument, baseOptions);
      }
export type DeleteExhibitionMutationHookResult = ReturnType<typeof useDeleteExhibitionMutation>;
export type DeleteExhibitionMutationResult = ApolloReactCommon.MutationResult<DeleteExhibitionMutation>;
export type DeleteExhibitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>;
export const DeleteExhibitorDocument = gql`
    mutation deleteExhibitor($id: ID!) {
  deleteExhibitor(id: $id)
}
    `;
export type DeleteExhibitorMutationFn = ApolloReactCommon.MutationFunction<DeleteExhibitorMutation, DeleteExhibitorMutationVariables>;

/**
 * __useDeleteExhibitorMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitorMutation, { data, loading, error }] = useDeleteExhibitorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExhibitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExhibitorMutation, DeleteExhibitorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExhibitorMutation, DeleteExhibitorMutationVariables>(DeleteExhibitorDocument, baseOptions);
      }
export type DeleteExhibitorMutationHookResult = ReturnType<typeof useDeleteExhibitorMutation>;
export type DeleteExhibitorMutationResult = ApolloReactCommon.MutationResult<DeleteExhibitorMutation>;
export type DeleteExhibitorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExhibitorMutation, DeleteExhibitorMutationVariables>;
export const LoginAsExhibitorDocument = gql`
    mutation loginAsExhibitor($exhibitorId: String!) {
  loginAsExhibitor(exhibitorId: $exhibitorId)
}
    `;
export type LoginAsExhibitorMutationFn = ApolloReactCommon.MutationFunction<LoginAsExhibitorMutation, LoginAsExhibitorMutationVariables>;

/**
 * __useLoginAsExhibitorMutation__
 *
 * To run a mutation, you first call `useLoginAsExhibitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsExhibitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsExhibitorMutation, { data, loading, error }] = useLoginAsExhibitorMutation({
 *   variables: {
 *      exhibitorId: // value for 'exhibitorId'
 *   },
 * });
 */
export function useLoginAsExhibitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginAsExhibitorMutation, LoginAsExhibitorMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginAsExhibitorMutation, LoginAsExhibitorMutationVariables>(LoginAsExhibitorDocument, baseOptions);
      }
export type LoginAsExhibitorMutationHookResult = ReturnType<typeof useLoginAsExhibitorMutation>;
export type LoginAsExhibitorMutationResult = ApolloReactCommon.MutationResult<LoginAsExhibitorMutation>;
export type LoginAsExhibitorMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginAsExhibitorMutation, LoginAsExhibitorMutationVariables>;
export const SendAccountNotificationMailDocument = gql`
    mutation sendAccountNotificationMail($id: ID!, $input: MailInput!) {
  sendAccountNotificationMail(id: $id, input: $input)
}
    `;
export type SendAccountNotificationMailMutationFn = ApolloReactCommon.MutationFunction<SendAccountNotificationMailMutation, SendAccountNotificationMailMutationVariables>;

/**
 * __useSendAccountNotificationMailMutation__
 *
 * To run a mutation, you first call `useSendAccountNotificationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccountNotificationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccountNotificationMailMutation, { data, loading, error }] = useSendAccountNotificationMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAccountNotificationMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendAccountNotificationMailMutation, SendAccountNotificationMailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendAccountNotificationMailMutation, SendAccountNotificationMailMutationVariables>(SendAccountNotificationMailDocument, baseOptions);
      }
export type SendAccountNotificationMailMutationHookResult = ReturnType<typeof useSendAccountNotificationMailMutation>;
export type SendAccountNotificationMailMutationResult = ApolloReactCommon.MutationResult<SendAccountNotificationMailMutation>;
export type SendAccountNotificationMailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendAccountNotificationMailMutation, SendAccountNotificationMailMutationVariables>;
export const SendBadgeNotificationMailDocument = gql`
    mutation sendBadgeNotificationMail($id: ID!, $input: MailInput!) {
  sendBadgeNotificationMail(id: $id, input: $input)
}
    `;
export type SendBadgeNotificationMailMutationFn = ApolloReactCommon.MutationFunction<SendBadgeNotificationMailMutation, SendBadgeNotificationMailMutationVariables>;

/**
 * __useSendBadgeNotificationMailMutation__
 *
 * To run a mutation, you first call `useSendBadgeNotificationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBadgeNotificationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBadgeNotificationMailMutation, { data, loading, error }] = useSendBadgeNotificationMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendBadgeNotificationMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBadgeNotificationMailMutation, SendBadgeNotificationMailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBadgeNotificationMailMutation, SendBadgeNotificationMailMutationVariables>(SendBadgeNotificationMailDocument, baseOptions);
      }
export type SendBadgeNotificationMailMutationHookResult = ReturnType<typeof useSendBadgeNotificationMailMutation>;
export type SendBadgeNotificationMailMutationResult = ApolloReactCommon.MutationResult<SendBadgeNotificationMailMutation>;
export type SendBadgeNotificationMailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBadgeNotificationMailMutation, SendBadgeNotificationMailMutationVariables>;
export const SendRemindNotificationMailDocument = gql`
    mutation sendRemindNotificationMail($id: ID!, $input: MailInput!) {
  sendRemindNotificationMail(id: $id, input: $input)
}
    `;
export type SendRemindNotificationMailMutationFn = ApolloReactCommon.MutationFunction<SendRemindNotificationMailMutation, SendRemindNotificationMailMutationVariables>;

/**
 * __useSendRemindNotificationMailMutation__
 *
 * To run a mutation, you first call `useSendRemindNotificationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRemindNotificationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRemindNotificationMailMutation, { data, loading, error }] = useSendRemindNotificationMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendRemindNotificationMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendRemindNotificationMailMutation, SendRemindNotificationMailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendRemindNotificationMailMutation, SendRemindNotificationMailMutationVariables>(SendRemindNotificationMailDocument, baseOptions);
      }
export type SendRemindNotificationMailMutationHookResult = ReturnType<typeof useSendRemindNotificationMailMutation>;
export type SendRemindNotificationMailMutationResult = ApolloReactCommon.MutationResult<SendRemindNotificationMailMutation>;
export type SendRemindNotificationMailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendRemindNotificationMailMutation, SendRemindNotificationMailMutationVariables>;
export const UpdateBadgeDocument = gql`
    mutation updateBadge($id: ID!, $input: BadgeInput!) {
  updateBadge(id: $id, input: $input) {
    ...BadgeDetail
  }
}
    ${BadgeDetailFragmentDoc}`;
export type UpdateBadgeMutationFn = ApolloReactCommon.MutationFunction<UpdateBadgeMutation, UpdateBadgeMutationVariables>;

/**
 * __useUpdateBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBadgeMutation, { data, loading, error }] = useUpdateBadgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBadgeMutation, UpdateBadgeMutationVariables>(UpdateBadgeDocument, baseOptions);
      }
export type UpdateBadgeMutationHookResult = ReturnType<typeof useUpdateBadgeMutation>;
export type UpdateBadgeMutationResult = ApolloReactCommon.MutationResult<UpdateBadgeMutation>;
export type UpdateBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>;
export const UpdateExhibitionDocument = gql`
    mutation updateExhibition($id: ID!, $input: ExhibitionInput!) {
  updateExhibition(id: $id, input: $input) {
    ...ExhibitionDetail
  }
}
    ${ExhibitionDetailFragmentDoc}`;
export type UpdateExhibitionMutationFn = ApolloReactCommon.MutationFunction<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>;

/**
 * __useUpdateExhibitionMutation__
 *
 * To run a mutation, you first call `useUpdateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExhibitionMutation, { data, loading, error }] = useUpdateExhibitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExhibitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>(UpdateExhibitionDocument, baseOptions);
      }
export type UpdateExhibitionMutationHookResult = ReturnType<typeof useUpdateExhibitionMutation>;
export type UpdateExhibitionMutationResult = ApolloReactCommon.MutationResult<UpdateExhibitionMutation>;
export type UpdateExhibitionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>;
export const UpdateCurrentUserPasswordDocument = gql`
    mutation updateCurrentUserPassword($password: String!) {
  updateCurrentUserPassword(password: $password) {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;
export type UpdateCurrentUserPasswordMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables>;

/**
 * __useUpdateCurrentUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserPasswordMutation, { data, loading, error }] = useUpdateCurrentUserPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateCurrentUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables>(UpdateCurrentUserPasswordDocument, baseOptions);
      }
export type UpdateCurrentUserPasswordMutationHookResult = ReturnType<typeof useUpdateCurrentUserPasswordMutation>;
export type UpdateCurrentUserPasswordMutationResult = ApolloReactCommon.MutationResult<UpdateCurrentUserPasswordMutation>;
export type UpdateCurrentUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables>;
export const UpdateExhibitorDocument = gql`
    mutation updateExhibitor($id: ID!, $input: ExhibitorInput!) {
  updateExhibitor(id: $id, input: $input) {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;
export type UpdateExhibitorMutationFn = ApolloReactCommon.MutationFunction<UpdateExhibitorMutation, UpdateExhibitorMutationVariables>;

/**
 * __useUpdateExhibitorMutation__
 *
 * To run a mutation, you first call `useUpdateExhibitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExhibitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExhibitorMutation, { data, loading, error }] = useUpdateExhibitorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExhibitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExhibitorMutation, UpdateExhibitorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExhibitorMutation, UpdateExhibitorMutationVariables>(UpdateExhibitorDocument, baseOptions);
      }
export type UpdateExhibitorMutationHookResult = ReturnType<typeof useUpdateExhibitorMutation>;
export type UpdateExhibitorMutationResult = ApolloReactCommon.MutationResult<UpdateExhibitorMutation>;
export type UpdateExhibitorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExhibitorMutation, UpdateExhibitorMutationVariables>;
export const UpdateCurrentExhibitorDocument = gql`
    mutation updateCurrentExhibitor($input: CurrentExhibitorInput!) {
  updateCurrentExhibitor(input: $input) {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;
export type UpdateCurrentExhibitorMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrentExhibitorMutation, UpdateCurrentExhibitorMutationVariables>;

/**
 * __useUpdateCurrentExhibitorMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentExhibitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentExhibitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentExhibitorMutation, { data, loading, error }] = useUpdateCurrentExhibitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentExhibitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrentExhibitorMutation, UpdateCurrentExhibitorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrentExhibitorMutation, UpdateCurrentExhibitorMutationVariables>(UpdateCurrentExhibitorDocument, baseOptions);
      }
export type UpdateCurrentExhibitorMutationHookResult = ReturnType<typeof useUpdateCurrentExhibitorMutation>;
export type UpdateCurrentExhibitorMutationResult = ApolloReactCommon.MutationResult<UpdateCurrentExhibitorMutation>;
export type UpdateCurrentExhibitorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrentExhibitorMutation, UpdateCurrentExhibitorMutationVariables>;
export const GetExhibitionByIdDocument = gql`
    query getExhibitionById($id: ID!) {
  getExhibitionById(id: $id) {
    ...ExhibitionDetail
  }
}
    ${ExhibitionDetailFragmentDoc}`;

/**
 * __useGetExhibitionByIdQuery__
 *
 * To run a query within a React component, call `useGetExhibitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExhibitionByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(GetExhibitionByIdDocument, baseOptions);
      }
export function useGetExhibitionByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(GetExhibitionByIdDocument, baseOptions);
        }
export type GetExhibitionByIdQueryHookResult = ReturnType<typeof useGetExhibitionByIdQuery>;
export type GetExhibitionByIdLazyQueryHookResult = ReturnType<typeof useGetExhibitionByIdLazyQuery>;
export type GetExhibitionByIdQueryResult = ApolloReactCommon.QueryResult<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>;
export const GetExhibitionByIdentifierDocument = gql`
    query getExhibitionByIdentifier($identifier: String!) {
  getExhibitionByIdentifier(identifier: $identifier) {
    ...ExhibitionDetail
  }
}
    ${ExhibitionDetailFragmentDoc}`;

/**
 * __useGetExhibitionByIdentifierQuery__
 *
 * To run a query within a React component, call `useGetExhibitionByIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionByIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionByIdentifierQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useGetExhibitionByIdentifierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExhibitionByIdentifierQuery, GetExhibitionByIdentifierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExhibitionByIdentifierQuery, GetExhibitionByIdentifierQueryVariables>(GetExhibitionByIdentifierDocument, baseOptions);
      }
export function useGetExhibitionByIdentifierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExhibitionByIdentifierQuery, GetExhibitionByIdentifierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExhibitionByIdentifierQuery, GetExhibitionByIdentifierQueryVariables>(GetExhibitionByIdentifierDocument, baseOptions);
        }
export type GetExhibitionByIdentifierQueryHookResult = ReturnType<typeof useGetExhibitionByIdentifierQuery>;
export type GetExhibitionByIdentifierLazyQueryHookResult = ReturnType<typeof useGetExhibitionByIdentifierLazyQuery>;
export type GetExhibitionByIdentifierQueryResult = ApolloReactCommon.QueryResult<GetExhibitionByIdentifierQuery, GetExhibitionByIdentifierQueryVariables>;
export const GetExhibitionsDocument = gql`
    query getExhibitions {
  getExhibitions {
    ...ExhibitionDetail
  }
}
    ${ExhibitionDetailFragmentDoc}`;

/**
 * __useGetExhibitionsQuery__
 *
 * To run a query within a React component, call `useGetExhibitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExhibitionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExhibitionsQuery, GetExhibitionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExhibitionsQuery, GetExhibitionsQueryVariables>(GetExhibitionsDocument, baseOptions);
      }
export function useGetExhibitionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExhibitionsQuery, GetExhibitionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExhibitionsQuery, GetExhibitionsQueryVariables>(GetExhibitionsDocument, baseOptions);
        }
export type GetExhibitionsQueryHookResult = ReturnType<typeof useGetExhibitionsQuery>;
export type GetExhibitionsLazyQueryHookResult = ReturnType<typeof useGetExhibitionsLazyQuery>;
export type GetExhibitionsQueryResult = ApolloReactCommon.QueryResult<GetExhibitionsQuery, GetExhibitionsQueryVariables>;
export const GetExhibitorByIdDocument = gql`
    query getExhibitorById($id: ID!) {
  getExhibitorById(id: $id) {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;

/**
 * __useGetExhibitorByIdQuery__
 *
 * To run a query within a React component, call `useGetExhibitorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExhibitorByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExhibitorByIdQuery, GetExhibitorByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExhibitorByIdQuery, GetExhibitorByIdQueryVariables>(GetExhibitorByIdDocument, baseOptions);
      }
export function useGetExhibitorByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExhibitorByIdQuery, GetExhibitorByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExhibitorByIdQuery, GetExhibitorByIdQueryVariables>(GetExhibitorByIdDocument, baseOptions);
        }
export type GetExhibitorByIdQueryHookResult = ReturnType<typeof useGetExhibitorByIdQuery>;
export type GetExhibitorByIdLazyQueryHookResult = ReturnType<typeof useGetExhibitorByIdLazyQuery>;
export type GetExhibitorByIdQueryResult = ApolloReactCommon.QueryResult<GetExhibitorByIdQuery, GetExhibitorByIdQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...ExhibitorDetail
  }
}
    ${ExhibitorDetailFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetExhibitorsDocument = gql`
    query getExhibitors($exhibitionId: String) {
  getExhibitors(exhibitionId: $exhibitionId) {
    ...ExhibitorForList
  }
}
    ${ExhibitorForListFragmentDoc}`;

/**
 * __useGetExhibitorsQuery__
 *
 * To run a query within a React component, call `useGetExhibitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitorsQuery({
 *   variables: {
 *      exhibitionId: // value for 'exhibitionId'
 *   },
 * });
 */
export function useGetExhibitorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExhibitorsQuery, GetExhibitorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExhibitorsQuery, GetExhibitorsQueryVariables>(GetExhibitorsDocument, baseOptions);
      }
export function useGetExhibitorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExhibitorsQuery, GetExhibitorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExhibitorsQuery, GetExhibitorsQueryVariables>(GetExhibitorsDocument, baseOptions);
        }
export type GetExhibitorsQueryHookResult = ReturnType<typeof useGetExhibitorsQuery>;
export type GetExhibitorsLazyQueryHookResult = ReturnType<typeof useGetExhibitorsLazyQuery>;
export type GetExhibitorsQueryResult = ApolloReactCommon.QueryResult<GetExhibitorsQuery, GetExhibitorsQueryVariables>;