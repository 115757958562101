import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDropzone } from 'react-dropzone'
import Modal from 'react-modal'
import { createUploadBadgesSampleCSV } from '../../utils'

Modal.setAppElement('#root')

interface Props {
  isOpen: boolean
  handleCloseModal(): void
  onUpload(formData: FormData): Promise<void>
}

const badgesSampleCSV = createUploadBadgesSampleCSV()

const UploadBadgesConfirmModal = (props: Props) => {
  const [isUploading, setIsUploading] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      let params = new FormData()
      params.append('file', acceptedFiles[0])
      setIsUploading(true)
      props.onUpload(params).finally(() => {
        setIsUploading(false)
      })
    },
    accept: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ],
  })

  return (
    <div className="modal confirm-modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="text-center w-100">バッジ一括入力 / Bulk Badge Input</h3>

            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-4">
              <div>
                バッジを一括登録したい場合、フォーマットに沿って記載し、
                <br />
                以下のボタンからアップロードしてください。
                <br />※ 社名、バッジ印刷氏名、メールアドレス、電話番号は必須です。
              </div>
              <div className="mt-2">
                If you want to register badges in bulk, fill in according to the format and upload
                from the button below.
                <br />
                *Company name, badge printing name, email address and phone number are required.
              </div>
            </div>

            <div>
              <div {...getRootProps()} className={'btn mb-4' + (isUploading ? ' loading' : '')}>
                <input {...getInputProps()} disabled={isUploading} />
                <div>
                  {isUploading ? (
                    <span>
                      ファイルアップロード中 / File Uploading&nbsp;
                      <i className="fa fa-sync-alt fa-spin"></i>
                    </span>
                  ) : (
                    <span>
                      一括登録ファイルアップロード
                      <br />
                      Bulk Registration File Upload
                    </span>
                  )}
                </div>
              </div>
              <div className="btn">
                <CSVLink data={badgesSampleCSV} download="badges.csv">
                  一括登録フォーマットダウンロード
                  <br />
                  Download Bulk Registration Format
                </CSVLink>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={props.handleCloseModal}>
              キャンセル / Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UploadBadgesConfirmModal
