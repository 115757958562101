import axios, { AxiosInstance, AxiosResponse } from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_URL

export const getApi = (jwtToken?: string | null) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
}

// login
export type loginParams = {
  accountId: string
  password: string
}
export type loginResponseData = {
  accountId: string
  password: string
  token: string
}
export type loginResponse = AxiosResponse<loginResponseData>
export const login = async (
  api: AxiosInstance,
  params: loginParams,
  type?: string
): Promise<loginResponse> => {
  let url
  if (type === 'admin') {
    url = '/admin/login'
  } else {
    url = '/login'
  }
  return await api.post(url, params)
}

// generate pdf
export type generatePdfParams = {
  url: string
}
export type generatePdfResponseData = {
  url: string
}
export type generatePdfResponse = AxiosResponse<generatePdfResponseData>
export const generatePdf = async (
  api: AxiosInstance,
  params: generatePdfParams
): Promise<generatePdfResponse> => {
  const url = '/pdf'
  return await api.post(url, params)
}

// upload exhibitors
export type uploadExhibitorsResponseData = {
  mimeType: string
  fileName: string
  encoding: string
}
export type uploadExhibitorsResponse = AxiosResponse<uploadExhibitorsResponseData>
export const uploadExhibitors = async (
  api: AxiosInstance,
  params: FormData
): Promise<uploadExhibitorsResponse> => {
  // FIXME: サーバ側でFormDataのパース方法がいまいちわからなかったのでクエリパラメータで送る
  const exhibitionId = params.get('exhibitionId')
  const url = '/upload/exhibitors?exhibition_id=' + exhibitionId
  return await api.post(url, params)
}

// upload badges
export type uploadBadgesResponseData = {
  mimeType: string
  fileName: string
  encoding: string
}
export type uploadBadgesResponse = AxiosResponse<uploadBadgesResponseData>
export const uploadBadges = async (
  api: AxiosInstance,
  params: FormData
): Promise<uploadExhibitorsResponse> => {
  const url = '/upload/badges'
  return await api.post(url, params)
}
