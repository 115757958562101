import React from 'react'
import { RouteProps } from 'react-router-dom'
import { ExhibitionDetailFragment } from '../../api'
import HeaderNav from './HeaderNav'
import Sidebar from './Sidebar'

type Props = RouteProps & {
  isWide?: boolean
  isDownload?: boolean
  exhibition?: ExhibitionDetailFragment | null
}

const DefaultLayout: React.FC<Props> = ({ children, isWide, isDownload, exhibition }: Props) => {
  return (
    <>
      <Sidebar exhibition={exhibition} />
      <main className="main-content">
        <HeaderNav isDownload={isDownload} exhibition={exhibition} />
        <div className="main-container">
          <section className={'section' + (isWide ? ' wide-section' : '')}>{children}</section>
        </div>
      </main>
    </>
  )
}

export default DefaultLayout
