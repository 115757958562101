export const defaultNewExhibitionDescriptionHtml = `出展社バッジの申請のご案内<br/>
<br/>
TSO International株式会社では、出展社バッジについても来場者バッジ同様、使用者の情報を登録し、出展社ご自身で印刷していただく必要がございます。<br/>
「出展社バッジ」申請用ページにて、情報登録を行い出展社バッジの発行を行ってください。<br/>
<br/>
～申請から発行までの流れ～<br/>
<ol>
  <li>
    出展社<br/>
    出展社バッジ申請用サイトにて必要な枚数分の登録を行ってください。<br/>
    ※枚数の制限はありません。
  </li>
  <li>申請したご利用者宛にメールにて通知が届きます。</li>
  <li>ご利用者ご自身で出展社バッジを印刷してご持参の上、各ホールの出入口付近に設置していますバッジホルダーからホルダーを取って入場してください。</li>
</ol>
※バッジ印刷済みの方は受付不要です。<br/>
※ホルダーは来場者と共通のものとなります。<br/>
※バッジは会期中7月16日・17日・18日にご使用いただきます。<br/>
<br/>
事前に出展社バッジを印刷していないご出展社は、南1ホール出入口にあります「出展社カウンター」へお越しいただき、出展社バッジの登録ならびに印刷を行ってからのご入場となります。<br/>
南1ホールの「出展社カウンター」は非常に混雑が予想されますため、事前に出展社バッジの登録ならびに印刷をして、ご持参いただきますようお願いいたします。<br/>
<br/>
出展社バッジの申請は以下の「出展社バッジを申請する」ボタンをクリックして申請してください。<br/>
ご不明な点がありましたら、展示会事務局へお問合せください。`

export const defaultNewExhibitionRemindNotificationMailBodyText = `本メールは下記のアドレス宛に送付されています。
{{mail}}
`

export const defaultNewExhibitionBadgeNotificationMailBodyText = `本メールは下記のアドレス宛に送付されています。
{{mail}}

下記のURLよりバッジのPDFをダウンロードすることができます。印字の上ご持参ください。
{{downloadUrl}}`

export const defaultNewExhibitionAccountNotificationMailBodyText = `本メールは下記のアドレス宛に送付されています。
{{mail}}

{{companyName}}
{{names}}

出展社の入場バッジ申請について、ご案内いたします。
TSO International（株）では、出展社バッジについても来場者バッジ同様
ご利用者の情報を登録し、ご自身で印刷・ご持参いただく必要がございます。
下記の申請用ページにて、情報登録を行ってください。

----------------------------------------------------------------------
■会社名： {{companyName}}
{{departmentNames}}

■ID : {{accountId}}
■パスワード: {{password}}
＜出展社バッジ申請用サイトログインページ＞
{{loginPageUrl}}
→ID・パスワードを入力して、ログインしてください。
----------------------------------------------------------------------

～申請から発行までの流れ～
1.上記の出展社バッジ申請用サイトにて、会場に来られるすべてのスタッフ分の登録を行ってください（枚数の制限はありません）。
2.申請したご利用者宛にメールにて通知が届きます。
3.ご利用者ご自身で出展社バッジを印刷してご持参の上、
各ホールの出入口付近に設置していますバッジホルダーからホルダーを取って入場してください。
※バッジ印刷済みの方は受付不要です。
※ホルダーは来場者と共通のものとなります。
※バッジは会期中7月16日・17日・18日にご使用いただきます。

事前に出展社バッジを印刷していないご出展社は、南1ホール出入口にあります「出展社カウンター」へお越しいただき、
出展社バッジの登録ならびに印刷を行ってからのご入場となります。
南1ホールの「出展社カウンター」は非常に混雑が予想されますため、事前に出展社バッジの登録ならびに印刷をして、
ご持参いただきますようお願いいたします。

----------------------------------------------------------------------
◆展示会に関するお問い合わせ先
展示会事務局
〒160-0004
東京都新宿区四谷3-2-1 フロントプレイス四谷ビル7階
TEL： 03-5363-1701　Email：office@tso-int.co.jp
営業時間：9:30〜18:30　※土日、祝祭日を除く
----------------------------------------------------------------
◆システムに関するお問い合わせ先
事前登録担当窓口　株式会社フリーダム・グループ
担当：佐藤
TEL:03-5733-4591（10:00 - 17:00 ※土日、祝祭日を除く）
E-mail：pregis.info@free-dom.net
----------------------------------------------------------------
※ ご質問に回答する際に個人を特定する情報をおうかがいすることが
ありますので、あらかじめご了承ください。`
