import Cookies from 'universal-cookie'

const COOKIE_API_KEY = 'fgd'

export const getIsExplainedCookie = (): string | undefined => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_API_KEY + 'is-explained')
}

export const setIsExplainedCookie = (): void => {
  const cookies = new Cookies()
  cookies.set(COOKIE_API_KEY + 'is-explained', true)
}

export const getAuthCookie = (type?: string): string | undefined => {
  const cookies = new Cookies()
  return cookies.get(type + COOKIE_API_KEY)
}

export const setAuthCookie = (token: string, type?: string, expires?: number): void => {
  const cookies = new Cookies()
  cookies.set(type + COOKIE_API_KEY, token, {
    path: '/',
    expires: expires ? new Date(expires * 1000) : undefined,
  })
}

export const removeAuthCookie = (type?: string): void => {
  const cookies = new Cookies()
  cookies.remove(type + COOKIE_API_KEY)
}
