import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import {
  ExhibitionDetailFragment,
  ExhibitorDetailFragment,
  ExhibitorForListFragment,
  useCreateExhibitorMutation,
  useUpdateCurrentExhibitorMutation,
  useUpdateExhibitorMutation,
} from '../../../api'
import countriesPath from '../../../assets/csv/countries.csv'
import { EditExhibitorForm, EditExhibitorSchema } from '../../../schema'
import { updateFieldsByPostalCode } from '../../../utils'

const countriesReq = new XMLHttpRequest()
countriesReq.open('get', countriesPath, true)
countriesReq.send()
let countries: string[] = []
countriesReq.onload = function () {
  countries = countriesReq.responseText.split('\n')
}

Modal.setAppElement('#root')

interface Props {
  type: 'new' | 'edit' | 'edit-by-exhibitor'
  exhibition: ExhibitionDetailFragment
  exhibitor?: ExhibitorDetailFragment | ExhibitorForListFragment
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const EditExhibitorModal = (props: Props) => {
  const [createExhibitorMutation] = useCreateExhibitorMutation()
  const [updateExhibitorMutation] = useUpdateExhibitorMutation()
  const [updateCurrentExhibitorMutation] = useUpdateCurrentExhibitorMutation()
  const [submitError, setSubmitError] = useState('')
  const { exhibitor, type } = props

  const formMethods = useForm<EditExhibitorForm>({
    validationSchema: EditExhibitorSchema,
  })
  const { register, handleSubmit, errors, reset, setValue } = formMethods

  const onSubmitSuccess = () => {
    props.handleSubmit()
    setSubmitError('')
  }

  const onModalClose = () => {
    props.handleCloseModal()
    setSubmitError('')
  }

  setTimeout(() => {
    if (!exhibitor?.boothsCount) setValue('boothsCount', 0)
  })

  useEffect(() => {
    if (exhibitor) {
      reset({ ...(exhibitor as any), type })
    } else {
      reset({ type })
    }
  }, [reset, exhibitor, type])

  const onSubmit = async ({ type: _, ...formData }: EditExhibitorForm) => {
    const exhibitionId = props.exhibition.id
    try {
      if (type === 'new') {
        await createExhibitorMutation({
          variables: { input: { exhibitionId, ...formData } },
        })
      } else if (type === 'edit-by-exhibitor') {
        await updateCurrentExhibitorMutation({
          variables: {
            input: {
              familyName: formData.familyName,
              givenName: formData.givenName,
              email: formData.email,
              department: formData.department,
              position: formData.position,
              familyName2: formData.familyName2,
              givenName2: formData.givenName2,
              email2: formData.email2,
              department2: formData.department2,
              position2: formData.position2,
              familyName3: formData.familyName3,
              givenName3: formData.givenName3,
              email3: formData.email3,
              department3: formData.department3,
              position3: formData.position3,
            },
          },
        })
      } else {
        await updateExhibitorMutation({
          variables: { id: exhibitor!.id, input: { exhibitionId, ...formData } },
        })
      }
      onSubmitSuccess()
    } catch (e) {
      // console.error(e)
      const apiErrors = ((e as any).graphQLErrors as { message: string }[]) || undefined
      if (
        apiErrors &&
        apiErrors.length > 0 &&
        apiErrors[0].message.startsWith('Exhibitor accountId Already Exists:')
      ) {
        setSubmitError('ログインIDはすでに使用されています。別のIDを指定してください。')
      } else {
        setSubmitError(type === 'new' ? '作成できませんでした' : '更新できませんでした')
      }
    }
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={onModalClose}
      >
        <div className="modal-content update-modal">
          <div className="modal-header">
            <button type="button" className="close" onClick={onModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FormContext {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <input type="hidden" ref={register} name="type" />
                <h3>アカウント情報 / Account Information</h3>
                <div className="form-section">
                  <div className="form-group">
                    <label>ログインID / Login ID</label>

                    <input
                      ref={register}
                      name="accountId"
                      type="text"
                      disabled={type === 'edit-by-exhibitor'}
                      className={classNames('form-control', {
                        'is-invalid': errors.accountId?.type === 'required',
                      })}
                    />
                    {errors.accountId && (
                      <div className="invalid-feedback">※ログインIDを入力してください。</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>パスワード / Password</label>

                    <input
                      ref={register}
                      name="password"
                      disabled={type === 'edit-by-exhibitor'}
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.password?.type === 'required',
                      })}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">※パスワードを入力してください。</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>展示会 / Exhibition</label>

                    <div>{props.exhibition.name}</div>
                  </div>
                </div>

                <h3>出展社基本情報 / Basic Exhibitor Information</h3>

                <div className="form-section">
                  <div className="form-group">
                    <label>構成展 / Area</label>

                    <select
                      ref={register}
                      name="composition"
                      className="form-control"
                      disabled={type === 'edit-by-exhibitor'}
                    >
                      <option value="">未指定</option>
                      {props.exhibition.compositions.map((country) => (
                        <option value={country} key={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>国名 / Country</label>

                    <select
                      ref={register}
                      name="country"
                      className="form-control"
                      disabled={type === 'edit-by-exhibitor'}
                    >
                      {countries.map((country) => (
                        <option value={country} key={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>社名 / Company Name</label>

                        <input
                          disabled={type === 'edit-by-exhibitor'}
                          ref={register}
                          name="companyName"
                          type="text"
                          className={classNames('form-control', {
                            'is-invalid': errors.companyName,
                          })}
                        />

                        {errors.companyName && (
                          <div className="invalid-feedback">※社名を入力してください。</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>社名カナ / Company Name (Katakana)</label>

                    <input
                      disabled={type === 'edit-by-exhibitor'}
                      ref={register}
                      name="companyNameKana"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>受付No. / Reception No.</label>

                    <input
                      ref={register}
                      name="receptionId"
                      type="text"
                      className="form-control"
                      disabled={type === 'edit-by-exhibitor'}
                    />
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>電話番号 / Telephone Number</label>
                    <input
                      ref={register}
                      name="tel"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.tel,
                      })}
                      disabled={type === 'edit-by-exhibitor'}
                    />

                    {errors.tel && (
                      <div className="invalid-feedback">※電話番号を入力してください。</div>
                    )}
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>郵便番号 / Postal Code</label>

                    <input
                      ref={register}
                      name="postalCode"
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        updateFieldsByPostalCode(e.target.value, setValue)
                      }}
                      size={8}
                      disabled={type === 'edit-by-exhibitor'}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>都道府県 / States</label>

                        <input
                          ref={register}
                          name="prefecture"
                          type="text"
                          className="form-control"
                          disabled={type === 'edit-by-exhibitor'}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>以下住所 / Address</label>

                        <input
                          ref={register}
                          name="municipality"
                          type="text"
                          className="form-control"
                          disabled={type === 'edit-by-exhibitor'}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-section">
                      <div className="form-group">
                        <label>ビル名 / Building Name</label>

                        <input
                          ref={register}
                          name="address"
                          type="text"
                          className="form-control"
                          disabled={type === 'edit-by-exhibitor'}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <h3>出展担当者情報 / Exhibitor Representative Information</h3>

                {[
                  '出展担当者1 / Representative 1',
                  '出展担当者2 / Representative 2',
                  '出展担当者3 / Representative 3',
                ].map((x, index) => (
                  <div key={index}>
                    <h5>{x}</h5>

                    <div className="form-section">
                      <div className="form-group">
                        <label>姓 / Last Name</label>

                        <input
                          ref={register}
                          name={`familyName${index > 0 ? index + 1 : ''}`}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="form-group">
                        <label>名 / First Name</label>

                        <input
                          ref={register}
                          name={`givenName${index > 0 ? index + 1 : ''}`}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="form-group">
                        <label>メールアドレス / Email Address</label>

                        <input
                          ref={register}
                          name={`email${index > 0 ? index + 1 : ''}`}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="form-group">
                        <label>部署 / Department</label>

                        <input
                          ref={register}
                          name={`department${index > 0 ? index + 1 : ''}`}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="form-group">
                        <label>役職 / Job Title</label>

                        <input
                          ref={register}
                          name={`position${index > 0 ? index + 1 : ''}`}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="form-section submit">
                  {submitError && <div className="alert alert-danger">{submitError}</div>}

                  <input
                    type="submit"
                    name="submit"
                    className="btn"
                    value={!type.startsWith('edit') ? '作成 / Register' : '更新 / Update'}
                  />
                </div>
              </form>
            </FormContext>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditExhibitorModal
