import classNames from 'classnames'
import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Redirect, RouteProps } from 'react-router-dom'
import { useAppContext } from '../../contexts/app'
import { LoginForm, LoginSchema } from '../../schema'
import DefaultLayout from '../common/DefaultAdminLayout'

const AdminLoginPage: React.FC<RouteProps> = (props: RouteProps) => {
  const { adminAuthToken, login } = useAppContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<LoginForm>({
    validationSchema: LoginSchema,
  })
  const { register, handleSubmit, errors, setError } = formMethods

  const onSubmit = async (formData: LoginForm) => {
    setIsLoading(true)
    await login(formData.accountId, formData.password, 'admin')
      .catch((err) => {
        setError('accountId', 'unauthorized', '')
        setError('password', 'unauthorized', '')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  if (adminAuthToken) {
    return <Redirect to={{ pathname: '/admin' }} />
  }

  return (
    <DefaultLayout>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="login-wrapper">
          <div className="form-section">
            <div className="form-group">
              <label>ログインID</label>

              <input
                ref={register}
                name="accountId"
                type="text"
                className={classNames('form-control', {
                  'is-invalid': errors.accountId?.type === 'required',
                })}
              />
              {errors.accountId && (
                <div className="invalid-feedback">※ログインIDを入力してください。</div>
              )}
            </div>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label>パスワード</label>

              <input
                ref={register}
                name="password"
                type="password"
                className={classNames('form-control', {
                  'is-invalid': errors.password?.type === 'required',
                })}
              />
              {errors.password && (
                <div className="invalid-feedback">※パスワードを入力してください。</div>
              )}
            </div>
          </div>

          <div className="form-section submit">
            <button
              type="submit"
              name="submit"
              className={classNames('btn', {
                'is-invalid':
                  errors.accountId?.type === 'unauthorized' &&
                  errors.password?.type === 'unauthorized',
              })}
              disabled={isLoading}
            >
              {!isLoading && <span>ログイン</span>}
              {isLoading && (
                <span>
                  ログイン中<i className="fa fa-sync-alt fa-spin"></i>
                </span>
              )}
            </button>
            <div className="invalid-feedback">
              ※認証に失敗しました。入力情報を確認してください。
            </div>
          </div>
        </form>
      </FormContext>
    </DefaultLayout>
  )
}

export default AdminLoginPage
