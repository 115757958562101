import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

interface Props {
  isOpen: boolean
  title: string
  handleCloseModal(): void
  errors?: { index: number; message: string }[]
}

const UploadResultModal = (props: Props) => {
  const onModalClose = () => {
    props.handleCloseModal()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={onModalClose}
      >
        <div className="modal-content update-modal">
          <div className="modal-header">
            {props.title}
            <button type="button" className="close" onClick={onModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-2">エラー内容 / Error Content</div>
            {props?.errors?.map((x) => (
              <div key={x.index}>
                {x.index}行目 / Line：{x.message}
              </div>
            ))}
            {!props?.errors && '不明なエラー / Unknown Error'}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UploadResultModal
