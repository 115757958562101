import { generatePdf, getApi, login, uploadBadges, uploadExhibitors } from '../repos/api'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from '../repos/cookies'

export default class AppService {
  async authenticate(accountId: string, password: string, type?: string): Promise<string> {
    const api = getApi()
    const response = await login(api, { accountId, password }, type)
    setAuthCookie(response.data.token, type)
    return response.data.token
  }

  async generatePdf(url: string, token: string) {
    const api = getApi(token)
    const response = await generatePdf(api, { url })
    return response
  }

  async uploadExhibitors(token: string, params: FormData) {
    const api = getApi(token)
    const response = await uploadExhibitors(api, params)
    return response
  }

  async uploadBadges(token: string, params: FormData) {
    const api = getApi(token)
    const response = await uploadBadges(api, params)
    return response
  }

  unauthenticate(type?: string) {
    removeAuthCookie(type)
  }

  getAuthToken(type?: string) {
    return getAuthCookie(type)
  }
}
