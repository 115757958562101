import React, { useEffect } from 'react'
import { Route, RouteProps, useLocation } from 'react-router-dom'
import { useAppContext } from '../../contexts/app'
import { getAuthQueries } from '../../utils'

type Props = RouteProps

const QueryAuthRoute: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const { authToken, setAuthToken } = useAppContext()
  const token = getAuthQueries(location.search)

  useEffect(() => {
    if (token) {
      setAuthToken(token)
    }
  }, [token, setAuthToken])

  if (!authToken) {
    return <></>
  }

  return <Route {...props} />
}

export default QueryAuthRoute
