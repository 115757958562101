import classNames from 'classnames'
import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { ExhibitorDetailFragment, useUpdateCurrentUserPasswordMutation } from '../../../api'
import { EditExhibitorPasswordForm, EditExhibitorPasswordSchema } from '../../../schema'

Modal.setAppElement('#root')

interface Props {
  exhibitor?: ExhibitorDetailFragment
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}

const EditExhibitorPasswordModal = (props: Props) => {
  const [updatePasswordMutation] = useUpdateCurrentUserPasswordMutation()
  const { exhibitor } = props

  const formMethods = useForm<EditExhibitorPasswordForm>({
    validationSchema: EditExhibitorPasswordSchema,
  })
  const { register, handleSubmit, errors } = formMethods

  const onSubmit = async (formData: EditExhibitorPasswordForm) => {
    await updatePasswordMutation({
      variables: {
        password: formData.password,
      },
    })
    props.handleSubmit()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content update-modal">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FormContext {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <h3>パスワード変更 / Change Password</h3>

                <div className="form-section">
                  <div className="form-group">
                    <label>ログインID / Login ID</label>

                    <div>{exhibitor?.accountId} </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>現在のパスワード / Current Password</label>

                    <div>{exhibitor?.password} </div>
                  </div>
                </div>

                <div className="form-section">
                  <div className="form-group">
                    <label>変更後のパスワード / New Password</label>

                    <input
                      ref={register}
                      name="password"
                      type="text"
                      className={classNames('form-control', {
                        'is-invalid': errors.password?.type === 'required',
                      })}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">※パスワードを入力してください。</div>
                    )}
                  </div>
                </div>

                <div className="form-section submit">
                  <input type="submit" name="submit" className="btn" value={'更新 / Update'} />
                </div>
              </form>
            </FormContext>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditExhibitorPasswordModal
