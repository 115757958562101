import React, { useMemo } from 'react'
import Modal from 'react-modal'
import {
  ExhibitionDetailFragment,
  ExhibitorDetailFragment,
  ExhibitorForListFragment,
  useSendAccountNotificationMailMutation,
  useSendRemindNotificationMailMutation,
} from '../../api'
import { formatDate, genNotificationMailMessage } from '../../utils'

Modal.setAppElement('#root')

interface Props {
  type: 'account' | 'remind'
  exhibitors: Array<ExhibitorDetailFragment | ExhibitorForListFragment>
  exhibition: ExhibitionDetailFragment
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}
const typeTextMap = { account: 'アカウント情報通知', remind: 'リマインド通知' }
const titleTextMap = { account: 'アカウント通知の一括送信', remind: 'リマインド通知の一括送信' }

const MailNotificationModal = (props: Props) => {
  const [sendAccountNotificationMailMutation] = useSendAccountNotificationMailMutation()
  const [sendRemindNotificationMailMutation] = useSendRemindNotificationMailMutation()
  const { type, exhibitors, exhibition } = props

  const canSend = useMemo(() => {
    return exhibitors.every((x) => x.email || x.email2 || x.email3)
  }, [exhibitors])

  const onSubmit = async () => {
    for (const exhibitor of exhibitors) {
      const targetUsers = [
        {
          email: exhibitor.email,
          familyName: exhibitor.familyName,
          givenName: exhibitor.givenName,
          department: exhibitor.department,
        },
        {
          email: exhibitor.email2,
          familyName: exhibitor.familyName2,
          givenName: exhibitor.givenName2,
          department: exhibitor.department2,
        },
        {
          email: exhibitor.email3,
          familyName: exhibitor.familyName3,
          givenName: exhibitor.givenName3,
          department: exhibitor.department3,
        },
      ].filter((x) => x.email) as {
        email: string
        familyName: string
        givenName: string
        department: string
      }[]

      const message = genNotificationMailMessage({
        type,
        exhibition,
        exhibitor,
        targetUsers,
      })

      const mail = {
        to: targetUsers.map((x) => x.email).join(','),
        subject: '【返信不可】 ' + typeTextMap[type],
        message,
      }
      if (type === 'account') {
        mail.subject = `[${exhibition.identifier}] 出展社バッジ申請のお願い`
        await sendAccountNotificationMailMutation({
          variables: { input: { ...mail }, id: exhibitor.id },
        })
      } else if (type === 'remind') {
        await sendRemindNotificationMailMutation({
          variables: { input: { ...mail }, id: exhibitor.id },
        })
      }
    }
    props.handleSubmit()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <form className="modal-content mail-modal">
          <div className="modal-header">
            <h3 className="text-center w-100">{titleTextMap[type]}</h3>
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>以下の出展社に通知します。</div>
            {exhibitors.map((exhibitor) => (
              <div key={exhibitor.id} className="is-notified-group">
                <div>{exhibitor.companyName}</div>
                {type === 'account' && (
                  <div>
                    {exhibitor.accountNotifiedAt ? (
                      <span>
                        <i className="checked mr-2" />
                        通知済 {formatDate(exhibitor.accountNotifiedAt)}
                      </span>
                    ) : (
                      '未通知'
                    )}
                  </div>
                )}
                {type === 'remind' && (
                  <div>
                    {exhibitor.remindNotifiedAt ? (
                      <span>
                        <i className="checked mr-2" />
                        通知済 {formatDate(exhibitor.remindNotifiedAt)}
                      </span>
                    ) : (
                      '未通知'
                    )}
                  </div>
                )}
              </div>
            ))}
            {!canSend && (
              <div className="mt-4">
                送信先メールアドレスが未設定の展示者が選択されています。
                {exhibitors
                  .filter((exhibitor) => !exhibitor.email)
                  .map((exhibitor) => (
                    <div key={exhibitor.id}>
                      <span>{exhibitor.companyName}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="modal-footer">
            {canSend && (
              <input
                onClick={() => onSubmit()}
                type="button"
                name="notification"
                className="btn mr-4"
                value="通知"
              />
            )}
            <button className="btn btn-secondary" onClick={props.handleCloseModal}>
              キャンセル
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default MailNotificationModal
