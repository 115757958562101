import React from 'react'
import { ExhibitionDetailFragment } from '../../api'

// import caferesLogo from '../../assets/images/caferes_logo.png'
// import eventJapanLogo from '../../assets/images/event_japan_logo.png'
// import fgdemoLogo from '../../assets/images/fgdemo_logo.png'
// import ilojLogo from '../../assets/images/iloj_logo.png'
// import sportecLogo from '../../assets/images/sportec_logo.png'
// import wellnessLogo from '../../assets/images/wellness_logo.png'

const Sidebar = (props: { exhibition?: ExhibitionDetailFragment | null }) => {
  const exhibition = props.exhibition
  return (
    <div className="sidebar">
      {exhibition && (
        <div>
          <img className="logo" alt="logo" src={exhibition.logoImageUrl} />
        </div>
      )}
      {/* {exhibition === 'FGDEMO' && (
        <>
          <img className="logo" alt="logo_iloj" src={ilojLogo} />
          <img className="logo" alt="logo_event_japan" src={eventJapanLogo} />
          <img className="logo" alt="logo_welnbess" src={wellnessLogo} />
        </>
      )}
      {exhibition === 'SPORTEC2023' && <img className="logo" alt="logo" src={sportecLogo} />}
      {exhibition === 'CAFERES2023' && <img className="logo" alt="logo" src={caferesLogo} />}
      {exhibition === 'ILOJ2023' && (
        <>
          <img className="logo" alt="logo" src={ilojLogo} />
          <img className="logo" alt="logo2" src={eventJapanLogo} />
        </>
      )}
      {exhibition === 'WELLNESS2023' && <img className="logo" alt="logo" src={wellnessLogo} />} */}
    </div>
  )
}

export default Sidebar
