import React, { useEffect, useMemo } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import {
  ExhibitionDetailFragment,
  ExhibitorDetailFragment,
  ExhibitorForListFragment,
  useSendAccountNotificationMailMutation,
  useSendRemindNotificationMailMutation,
} from '../../api'
import { MailForm, MailSchema } from '../../schema'
import { formatDate, genNotificationMailMessage } from '../../utils'

Modal.setAppElement('#root')

interface Props {
  title: string
  type: 'account' | 'remind'
  exhibition: ExhibitionDetailFragment
  exhibitor: ExhibitorDetailFragment | ExhibitorForListFragment
  notifiedAt?: string | null
  isOpen: boolean
  handleCloseModal(): void
  handleSubmit(): void
}
const typeTextMap = {
  account: 'アカウント情報通知',
  remind: 'リマインド通知',
}

const MailNotificationModal = (props: Props) => {
  const [sendAccountNotificationMailMutation] = useSendAccountNotificationMailMutation()
  const [sendRemindNotificationMailMutation] = useSendRemindNotificationMailMutation()
  const formMethods = useForm<MailForm>({
    validationSchema: MailSchema,
  })
  const { type, exhibition, exhibitor } = props
  const { register, handleSubmit, errors, reset } = formMethods

  const targetUsers = [
    {
      email: exhibitor.email,
      familyName: exhibitor.familyName,
      givenName: exhibitor.givenName,
      department: exhibitor.department,
    },
    {
      email: exhibitor.email2,
      familyName: exhibitor.familyName2,
      givenName: exhibitor.givenName2,
      department: exhibitor.department2,
    },
    {
      email: exhibitor.email3,
      familyName: exhibitor.familyName3,
      givenName: exhibitor.givenName3,
      department: exhibitor.department3,
    },
  ].filter((x) => x.email) as {
    email: string
    familyName: string
    givenName: string
    department: string
  }[]
  const canSend = targetUsers.length > 0

  const message = useMemo(() => {
    return genNotificationMailMessage({
      type,
      exhibition,
      exhibitor,
      targetUsers,
    })
  }, [exhibition, exhibitor, targetUsers, type])

  useEffect(() => {
    reset({ message })
  }, [message, reset])

  const onSubmit = async (formData: MailForm) => {
    const mail = {
      to: targetUsers.map((x) => x.email).join(','),
      subject: '【返信不可】 ' + typeTextMap[type],
      message: formData.message,
    }

    if (type === 'account') {
      mail.subject = `[${exhibition.identifier}] 出展社バッジ申請のお願い`
      await sendAccountNotificationMailMutation({
        variables: { input: { ...mail }, id: exhibitor.id },
      })
    } else if (type === 'remind') {
      await sendRemindNotificationMailMutation({
        variables: { input: { ...mail }, id: exhibitor.id },
      })
    }

    props.handleSubmit()
  }

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className="modal-content mail-modal">
            <div className="modal-header">
              <h3 className="text-center w-100">{props.title}</h3>
              <button type="button" className="close" onClick={props.handleCloseModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="is-notified-group mb-4">
                <div>{typeTextMap[type]}</div>
                <div>
                  {props.notifiedAt ? (
                    <span>
                      <i className="checked mr-2" />
                      通知済 {formatDate(props.notifiedAt)}
                    </span>
                  ) : (
                    '未'
                  )}
                </div>
              </div>
              {canSend && (
                <div>
                  <div className="mb-2">
                    下記内容を {targetUsers.map((x) => x.email).join(',')} にメール通知します。
                    <br />
                    よろしければ「通知」ボタンを押してください。
                  </div>
                  <textarea
                    ref={register}
                    name="message"
                    rows={message.split('\n').length}
                    className="form-control"
                  />
                  {errors.message && (
                    <div className="invalid-feedback">※メール内容を入力してください。</div>
                  )}
                </div>
              )}
              {!canSend && <div>送信先メールアドレスが未設定です。</div>}
            </div>
            <div className="modal-footer">
              {canSend && <input type="submit" name="submit" className="btn mr-4" value="通知" />}
              <button className="btn btn-secondary" onClick={props.handleCloseModal}>
                キャンセル
              </button>
            </div>
          </form>
        </FormContext>
      </Modal>
    </div>
  )
}

export default MailNotificationModal
