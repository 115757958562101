import { useParams } from 'react-router-dom'
import { useGetExhibitionByIdentifierQuery } from './api'

export const useExhibitionFromParams = () => {
  const { exhibitionId } = useParams<{ exhibitionId: string }>()

  const exhibitionQuery = useGetExhibitionByIdentifierQuery({
    variables: {
      identifier: exhibitionId,
    },
  })
  const exhibition = exhibitionQuery.data?.getExhibitionByIdentifier

  return {
    exhibitionId,
    exhibition,
  }
}
