import React, { Suspense, useCallback, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import {
  ExhibitorDetailFragment,
  ExhibitorForListFragment,
  useDeleteExhibitorMutation,
  useGetExhibitionByIdQuery,
  useGetExhibitorsQuery,
  useLoginAsExhibitorMutation,
} from '../../api'
import { useAppContext } from '../../contexts/app'
import { setAuthCookie, setIsExplainedCookie } from '../../repos/cookies'
import { createBadgesCSV, createExhibitorsCSV } from '../../utils'
import DefaultLayout from '../common/DefaultAdminLayout'
import ConfirmModal from '../modals/ConfirmModal'
import MailNotificationBulkModal from '../modals/MailNotificationBulkModal'
import MailNotificationModal from '../modals/MailNotificationModal'
import EditExhibitorModal from './modals/EditExhibitorModal'
import UploadResultModal from './modals/UploadResultModal'

const AdminExhibitionDetailPage = () => {
  const { uploadExhibitors } = useAppContext()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const [showModal, setShowModal] = useState<string | undefined>(undefined)
  const [selectedComposition, setSelectedComposition] = useState<string>('')
  const [searchText, setSearchText] = useState('')
  const [uploadExhibitorsErrors, setUploadExhibitorsErrors] = useState<
    { index: number; message: string }[] | undefined
  >(undefined)

  const [selectedExhibitorIds, setSelectedExhibitorIds] = useState<string[]>([])

  const [deleteExhibitorMutation] = useDeleteExhibitorMutation()
  const [loginAsExhibitorMutation] = useLoginAsExhibitorMutation()
  const exhibitionDetailQuery = useGetExhibitionByIdQuery({
    variables: {
      id: exhibitionId!,
    },
  })
  const exhibitorsQuery = useGetExhibitorsQuery({
    variables: {
      exhibitionId: exhibitionId!,
    },
  })
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const data = exhibitorsQuery.data
  const exhibition = exhibitionDetailQuery.data?.getExhibitionById
  const exhibitors = data?.getExhibitors

  const filteredExhibitors = useMemo(() => {
    if (!exhibitors) {
      return []
    }
    // 構成展をプルダウンから選択
    let tmp = exhibitors.filter((x) => {
      if (!selectedComposition) {
        return true
      }
      return selectedComposition === x.composition
    })

    // FIXME: useDebounce使った方が良いが、type errorでinstallできなかった
    // キーワードから検索（ログインID、社名、社名カナ、担当者姓、担当者名、メールアドレス、受付No）
    return tmp.filter((x) => {
      if (!searchText) {
        return true
      }
      if (
        x.accountId.includes(searchText) ||
        (x.companyName || '').includes(searchText) ||
        (x.email || '').includes(searchText) ||
        (x.familyName || '').includes(searchText) ||
        (x.givenName || '').includes(searchText) ||
        (x.email2 || '').includes(searchText) ||
        (x.familyName2 || '').includes(searchText) ||
        (x.givenName2 || '').includes(searchText) ||
        (x.email3 || '').includes(searchText) ||
        (x.familyName3 || '').includes(searchText) ||
        (x.givenName3 || '').includes(searchText) ||
        (x.receptionId || '').includes(searchText) ||
        (x.companyNameKana || '').includes(searchText) ||
        x.badges?.some((badge) => (badge.barcodeId || '').includes(searchText)) ||
        x.badges?.some((badge) => (badge.companyName || '').includes(searchText)) ||
        x.badges?.some((badge) => (badge.displayName || '').includes(searchText))
      ) {
        return true
      }
      return false
    })
  }, [exhibitors, searchText, selectedComposition])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      let params = new FormData()
      params.append('file', acceptedFiles[0])
      params.append('exhibitionId', exhibitionId)
      setIsUploading(true)
      await uploadExhibitors(params).catch((error) => {
        const errors = error.response?.data?.err?.errors as
          | {
              index: number
              message: string
            }[]
          | undefined
        setUploadExhibitorsErrors(errors)
        setShowModal('upload-exhibitors-result')
        setIsUploading(false)
      })
      setIsUploading(false)
      await exhibitorsQuery.refetch()
    },
  })

  // MARK: 一括出力は絞り込み・選択状態にかかわらず全出展社が対象
  const exhibitorsCSV = useMemo(() => {
    if (!data) {
      return null
    }
    return createExhibitorsCSV(data.getExhibitors)
  }, [data])

  const badgesCSV = useMemo(() => {
    if (!data) {
      return null
    }
    return createBadgesCSV(data.getExhibitors)
  }, [data])

  const isSelectedAllFilteredExhibitors = useMemo(() => {
    if (filteredExhibitors.length <= 0) return false
    return filteredExhibitors.every((x) => selectedExhibitorIds.includes(x.id))
  }, [filteredExhibitors, selectedExhibitorIds])

  const toggleAllSelectedExhibitors = useCallback(() => {
    if (filteredExhibitors.length <= 0) return
    if (isSelectedAllFilteredExhibitors) {
      setSelectedExhibitorIds(
        selectedExhibitorIds.filter((x) => !filteredExhibitors.map((y) => y.id).includes(x))
      )
    } else {
      setSelectedExhibitorIds(filteredExhibitors.map((x) => x.id).concat([]))
    }
  }, [filteredExhibitors, isSelectedAllFilteredExhibitors, selectedExhibitorIds])

  const isSelectedExhibitor = useCallback(
    (id: string) => {
      return selectedExhibitorIds.includes(id)
    },
    [selectedExhibitorIds]
  )

  const toggleSelectedExhibitor = useCallback(
    (id: string) => {
      if (isSelectedExhibitor(id)) {
        setSelectedExhibitorIds((current) => current.filter((exhibitorId) => exhibitorId !== id))
      } else {
        setSelectedExhibitorIds((current) => current.concat([id]))
      }
    },
    [isSelectedExhibitor]
  )

  const selectedExhibitors = useMemo(() => {
    if (!exhibitors) {
      return []
    }
    return exhibitors.filter((x) => selectedExhibitorIds.includes(x.id))
  }, [exhibitors, selectedExhibitorIds])

  const onChangeExhibitor = async () => {
    setShowModal(undefined)
    await exhibitorsQuery.refetch()
  }

  const onChangeExhibitors = async () => {
    setSelectedExhibitorIds([])
    setShowModal(undefined)
    await exhibitorsQuery.refetch()
  }

  const deleteExhibitor = async (id: string) => {
    await deleteExhibitorMutation({
      variables: { id },
    })
    setSelectedExhibitorIds((current) => current.filter((exhibitor) => exhibitor !== id))
    setShowModal(undefined)
    await exhibitorsQuery.refetch()
  }

  const loginAsExhibitor = async (
    exhibitor: ExhibitorDetailFragment | ExhibitorForListFragment
  ) => {
    const res = await loginAsExhibitorMutation({
      variables: { exhibitorId: exhibitor.id },
    })
    const authToken = res.data!.loginAsExhibitor
    setAuthCookie(authToken)
    setIsExplainedCookie()

    window.open(`/#/${exhibitor.exhibition.identifier}`)
  }

  if (!exhibitors || !exhibition) {
    return (
      <DefaultLayout isWide>
        <Suspense fallback={<div>...Loading</div>}>
          <div className="text-center">
            <i className="fa fa-sync-alt fa-spin"></i>
          </div>
        </Suspense>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout isWide exhibition={exhibition}>
      <div className="btn-group">
        <div className="btn mr-3" onClick={() => setShowModal('new')}>
          新規
        </div>
        <EditExhibitorModal
          type="new"
          exhibition={exhibition}
          isOpen={showModal === 'new'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onChangeExhibitor()}
        />
        <div {...getRootProps()} className={'btn mr-3' + (isUploading ? ' loading' : '')}>
          <input {...getInputProps()} disabled={isUploading} />
          <div>
            {isUploading ? (
              <span>
                出展社作成中<i className="fa fa-sync-alt fa-spin"></i>
              </span>
            ) : (
              '出展社一括入力'
            )}
          </div>
        </div>
        {exhibitorsCSV && (
          <div className="btn mr-3">
            <CSVLink data={exhibitorsCSV} download="exhibitors.csv">
              出展社一括出力
            </CSVLink>
          </div>
        )}
        <button
          className="btn mr-3"
          onClick={() => setShowModal('bulk-account')}
          disabled={!selectedExhibitors.length}
        >
          アカウント通知一括送信
        </button>
        <MailNotificationBulkModal
          type="account"
          exhibition={exhibition}
          exhibitors={selectedExhibitors}
          isOpen={showModal === 'bulk-account'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onChangeExhibitors()}
        />
        <button
          className="btn mr-3"
          onClick={() => setShowModal('bulk-remind')}
          disabled={!selectedExhibitors.length}
        >
          リマインド通知一括送信
        </button>
        <MailNotificationBulkModal
          type="remind"
          exhibition={exhibition}
          exhibitors={selectedExhibitors}
          isOpen={showModal === 'bulk-remind'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onChangeExhibitors()}
        />
        {badgesCSV && (
          <div className="btn">
            <CSVLink data={badgesCSV} download="badges.csv">
              バッジ一括出力
            </CSVLink>
          </div>
        )}
      </div>

      <div className="my-3">
        <div className="form-inline">
          <div className="form-group mr-3">
            <div className="mr-2">構成展</div>
            <select
              className="form-control"
              value={selectedComposition}
              onChange={(e) => {
                setSelectedComposition(e.target.value)
              }}
            >
              <option value="">全て</option>
              {exhibition.compositions.map((x, index) => (
                <option key={index} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="キーワード検索"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={isSelectedAllFilteredExhibitors}
                onChange={() => toggleAllSelectedExhibitors()}
              />
            </th>
            <th>出展社名</th>
            <th>通知</th>
            <th className="badge-header">
              バッジ
              <br />
              登録数
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredExhibitors.map((exhibitor, i) => {
            return (
              <tr key={i}>
                <td>
                  <input
                    type="checkbox"
                    checked={isSelectedExhibitor(exhibitor.id)}
                    onChange={() => toggleSelectedExhibitor(exhibitor.id)}
                  />
                </td>
                <td className="w-50">{exhibitor.companyName}</td>
                <td className="mail-link-group">
                  <div>
                    <span className="mail-is-notified">
                      {exhibitor.accountNotifiedAt ? <i className="checked" /> : '未'}
                    </span>
                    <div
                      className="mail-link"
                      onClick={() => setShowModal(exhibitor.id + '-account')}
                    >
                      アカウント通知
                    </div>
                    <MailNotificationModal
                      exhibition={exhibition}
                      exhibitor={exhibitor}
                      title={exhibitor.companyName!}
                      type="account"
                      notifiedAt={exhibitor.accountNotifiedAt}
                      isOpen={showModal === exhibitor.id + '-account'}
                      handleCloseModal={() => setShowModal(undefined)}
                      handleSubmit={() => onChangeExhibitor()}
                    />
                  </div>
                  <div>
                    <span className="mail-is-notified">
                      {exhibitor.remindNotifiedAt ? <i className="checked" /> : '未'}
                    </span>
                    <div
                      className="mail-link"
                      onClick={() => setShowModal(exhibitor.id + '-remind')}
                    >
                      リマインド通知
                    </div>
                    <MailNotificationModal
                      exhibition={exhibition}
                      exhibitor={exhibitor}
                      title={exhibitor.companyName!}
                      type="remind"
                      notifiedAt={exhibitor.remindNotifiedAt}
                      isOpen={showModal === exhibitor.id + '-remind'}
                      handleCloseModal={() => setShowModal(undefined)}
                      handleSubmit={() => onChangeExhibitor()}
                    />
                  </div>
                </td>
                <td>{exhibitor.badges.length}</td>
                <td className="tight-4">
                  <div
                    className="btn"
                    onClick={() => {
                      loginAsExhibitor(exhibitor)
                    }}
                  >
                    代理ログイン
                  </div>
                </td>

                <td className="tight-2">
                  <div className="btn" onClick={() => setShowModal(exhibitor.id + '-edit')}>
                    編集
                  </div>
                  <EditExhibitorModal
                    type="edit"
                    exhibitor={exhibitor}
                    exhibition={exhibition}
                    isOpen={showModal === exhibitor.id + '-edit'}
                    handleCloseModal={() => setShowModal(undefined)}
                    handleSubmit={() => onChangeExhibitor()}
                  />
                </td>
                <td className="tight-2">
                  <div className="btn" onClick={() => setShowModal(exhibitor.id + '-delete')}>
                    削除
                  </div>
                  <ConfirmModal
                    title={`出展社 ${exhibitor.accountId} を削除します。よろしいですか？`}
                    isOpen={showModal === exhibitor.id + '-delete'}
                    handleCloseModal={() => setShowModal(undefined)}
                    handleSubmit={() => deleteExhibitor(exhibitor.id)}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <UploadResultModal
        title="出展社一括入力エラー"
        isOpen={showModal === 'upload-exhibitors-result'}
        handleCloseModal={() => setShowModal(undefined)}
        errors={uploadExhibitorsErrors}
      />
    </DefaultLayout>
  )
}

export default AdminExhibitionDetailPage
