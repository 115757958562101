import React, { Suspense, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { BadgeModel, useCurrentUserQuery, useDeleteBadgeMutation } from '../api'
import { useAppContext } from '../contexts/app'
import { useExhibitionFromParams } from '../hooks'
import EditExhibitorModal from './admin/modals/EditExhibitorModal'
import EditExhibitorPasswordModal from './admin/modals/EditExhibitorPasswordModal'
import UploadResultModal from './admin/modals/UploadResultModal'
import DefaultLayout from './common/DefaultLayout'
import BadgeMailNotificationModal from './modals/BadgeMailNotificationModal'
import ConfirmModal from './modals/ConfirmModal'
import EditBadgeModal from './modals/EditBadgeModal'
import UploadBadgesConfirmModal from './modals/UploadBdgesConfirmModal'

const HomePage = () => {
  const { exhibitionId } = useExhibitionFromParams()
  const [showModal, setShowModal] = useState<string | undefined>(undefined)
  const [badgeLoadingsMap, setBadgeLoadings] = useState<{ [key: string]: boolean }>({})
  const [uploadBadgesErrors, setUploadBadgesErrors] = useState<
    { index: number; message: string }[] | undefined
  >(undefined)
  const [searchText, setSearchText] = useState('')

  const [deleteBadgeMutation] = useDeleteBadgeMutation()

  const { downloadBadge, uploadBadges } = useAppContext()
  const currentUser = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  })
  const refetch = currentUser.refetch
  const exhibitor = currentUser.data?.currentUser
  const exhibition = exhibitor?.exhibition

  const onUploadBadges = async (params: FormData) => {
    try {
      await uploadBadges(params)
      await currentUser.refetch()
      setShowModal(undefined)
    } catch (error) {
      const errors = (error as any).response?.data?.err?.errors as
        | {
            index: number
            message: string
          }[]
        | undefined
      setUploadBadgesErrors(errors)
      setShowModal('upload-badges-result')
    }
  }

  const badges = exhibitor?.badges || []

  const filteredBadges = useMemo(() => {
    if (!badges) {
      return []
    }
    return badges.filter((badge) => {
      if (!searchText) {
        return true
      }
      if (
        (badge.barcodeId || '').includes(searchText) ||
        (badge.companyName || '').includes(searchText) ||
        (badge.displayName || '').includes(searchText)
      ) {
        return true
      }
      return false
    })
  }, [badges, searchText])

  if (!exhibitor || !exhibition) {
    return (
      <DefaultLayout>
        <Suspense fallback={<div>...Loading</div>}>
          <div className="text-center">
            <i className="fa fa-sync-alt fa-spin"></i>
          </div>
        </Suspense>
      </DefaultLayout>
    )
  }

  if (exhibition && exhibition.identifier !== exhibitionId) {
    // MARK: ログイン済みの展示会とURLの展示会が異なる時
    // ログイン済みの展示会のURLにリダイレクト
    return <Redirect to={`/${exhibition.identifier}`} />
  }

  const download = async (badge: BadgeModel) => {
    if (badgeLoadingsMap[badge.id]) return
    setBadgeLoadings((previousState) => ({ ...previousState, [badge.id]: true }))
    try {
      await downloadBadge(badge)
    } catch (e) {
      setBadgeLoadings((previousState) => ({ ...previousState, [badge.id]: false }))
      return
    }
    setBadgeLoadings((previousState) => ({ ...previousState, [badge.id]: false }))
  }

  const onEdited = async () => {
    setShowModal(undefined)
    await refetch()
  }

  const deleteBadge = async (id: string) => {
    await deleteBadgeMutation({
      variables: { id },
    })
    setShowModal(undefined)
    await refetch()
  }

  return (
    <DefaultLayout isWide exhibition={exhibition}>
      <h4 className="mb-2">{exhibitor.companyName}</h4>

      <div>
        新たに出展社バッジを発行する場合は、「新規バッジ登録」ボタンをクリックして登録してください。
        <br />
        出展社バッジを発行する場合は、「バッジ」ボタンを押してPDFを表示させ印刷してご利用ください。
        <br />
        情報変更する場合は、「編集」ボタンを押して編集してください。
      </div>
      <div className="mt-2">
        To issue a new exhibitor badge, click the "New Badge Registration" button to register.
        <br />
        To issue an exhibitor badge, press the "Badge" button to display the PDF and print it.
        <br />
        To change the information, press the "Edit" button to edit.
      </div>

      <div className="btn-group btn-wrap mt-2">
        <div className="btn mr-2" onClick={() => setShowModal('upload-badges-confirm')}>
          バッジ一括入力 / Bulk Badge Input
        </div>
        <UploadBadgesConfirmModal
          isOpen={showModal === 'upload-badges-confirm'}
          handleCloseModal={() => setShowModal(undefined)}
          onUpload={onUploadBadges}
        />
        <div className="btn mr-2" onClick={() => setShowModal('create-badge')}>
          新規バッジ登録 / New Badge Registration
        </div>
        <EditBadgeModal
          type="create"
          exhibitor={exhibitor}
          isOpen={showModal === 'create-badge'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onEdited()}
        />

        <div className="btn mr-2" onClick={() => setShowModal('edit-exhibitor')}>
          出展社情報確認 / Confirm Exhibitor Information
        </div>
        <EditExhibitorModal
          type="edit-by-exhibitor"
          exhibition={exhibition}
          exhibitor={exhibitor}
          isOpen={showModal === 'edit-exhibitor'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onEdited()}
        />
        <div
          className="btn mr-2 btn-secondary"
          onClick={() => setShowModal('update-exhibitor-password')}
        >
          パスワード変更 / Change Password
        </div>
        <EditExhibitorPasswordModal
          exhibitor={exhibitor}
          isOpen={showModal === 'update-exhibitor-password'}
          handleCloseModal={() => setShowModal(undefined)}
          handleSubmit={() => onEdited()}
        />
      </div>

      <div className="my-3">
        <div className="form-inline">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="キーワード検索"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="text-right">
        バッジ登録数 / Number of Badge Registrations {badges.length} 枚 / Badge
      </div>
      <table>
        <tbody>
          {filteredBadges.map((badge, i) => {
            return (
              <tr key={i}>
                <td>{badge.companyName}</td>

                <td>{badge.department}</td>

                <td>{badge.position}</td>

                <td>{badge.displayName}</td>

                <td>
                  <div>
                    <span className="mail-is-notified-no-width">
                      {badge.badgeNotifiedAt ? <i className="checked" /> : '未 / Unsent'}
                    </span>
                    <br />
                    <div className="mail-link" onClick={() => setShowModal(badge.id + '-badge')}>
                      通知
                      <br />
                      Notification
                    </div>
                    <BadgeMailNotificationModal
                      exhibition={exhibition}
                      title={badge.companyName!}
                      badgeId={badge.id}
                      targetEmail={badge.email}
                      notifiedAt={badge.badgeNotifiedAt}
                      isOpen={showModal === badge.id + '-badge'}
                      handleCloseModal={() => setShowModal(undefined)}
                      handleSubmit={() => onEdited()}
                    />
                  </div>
                </td>

                <td className="tight-3">
                  <div
                    className={'btn' + (badgeLoadingsMap[badge.id] ? ' loading' : '')}
                    onClick={() => download(badge)}
                  >
                    {!badgeLoadingsMap[badge.id] && (
                      <span>
                        バッジ
                        <br />
                        Badge
                      </span>
                    )}
                    {badgeLoadingsMap[badge.id] && (
                      <span>
                        作成中
                        <br />
                        Creating<i className="fa fa-sync-alt fa-spin"></i>
                      </span>
                    )}
                  </div>
                </td>

                <td className="tight-2">
                  <div className="btn" onClick={() => setShowModal(badge.id + '-edit')}>
                    編集
                    <br />
                    Edit
                  </div>
                  <EditBadgeModal
                    badge={badge}
                    exhibitor={exhibitor}
                    isOpen={showModal === badge.id + '-edit'}
                    handleCloseModal={() => setShowModal(undefined)}
                    handleSubmit={() => onEdited()}
                  />
                </td>

                <td className="tight-3">
                  <div className="btn" onClick={() => setShowModal(badge.id + '-delete')}>
                    削除
                    <br />
                    Delete
                  </div>
                  <ConfirmModal
                    title={
                      <span>
                        バッジを削除します。よろしいですか？
                        <br />
                        Remove badge. Is it OK?
                      </span>
                    }
                    isOpen={showModal === badge.id + '-delete'}
                    handleCloseModal={() => setShowModal(undefined)}
                    handleSubmit={() => deleteBadge(badge.id)}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <UploadResultModal
        title="バッジ一括入力エラー / Bulk Badge Input Error"
        isOpen={showModal === 'upload-badges-result'}
        handleCloseModal={() => setShowModal(undefined)}
        errors={uploadBadgesErrors}
      />
    </DefaultLayout>
  )
}

export default HomePage
