import queryString from 'query-string'
import { ExhibitionDetailFragment, ExhibitorDetailFragment, ExhibitorForListFragment } from '../api'
import { Yubinbango } from '../lib/yubinbango'

export * from './genCsv'

export const updateFieldsByPostalCode = (
  code: string,
  setValue: (field: string, value: any, isValidate: boolean) => void
) => {
  new Yubinbango(code, (x: any) => {
    if (x.region !== '') {
      setValue('prefecture', x.region, true)
    }
    if (x.locality !== '' || x.street !== '') {
      setValue('municipality', x.locality + x.street, true)
    }
  })
}

export const formatDate = (date: string | Date): string => {
  date = new Date(date)
  if (!date.getFullYear()) return ''
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
}

export const getAuthQueries = (search: string) => {
  const queryParams = queryString.parse(search)
  let token
  if (queryParams.token instanceof Array) {
    token = queryParams.token[0]
  } else {
    token = queryParams.token as string | undefined
  }
  return token
}

export const genNotificationMailMessage = ({
  exhibition,
  exhibitor,
  type,
  targetUsers,
}: {
  type: 'account' | 'remind'
  exhibition: ExhibitionDetailFragment
  exhibitor: ExhibitorDetailFragment | ExhibitorForListFragment
  targetUsers: {
    email?: string
    department?: string
    familyName?: string
    givenName?: string
  }[]
}) => {
  if (type === 'account') {
    return exhibition.accountNotificationMailBodyText
      .replace(/\{\{mail\}\}/g, targetUsers.map((x) => x.email).join('\n'))
      .replace(
        /\{\{names\}\}/g,
        targetUsers
          .filter((x) => x.familyName || x.givenName)
          .map((x) => `${x.familyName} ${x.givenName} 様`)
          .join('\n')
      )
      .replace(
        /\{\{departmentNames\}\}/g,
        targetUsers
          .filter((x) => x.familyName || x.givenName)
          .map((x) => `■担当者名: ${x.department || ''} ${x.familyName} ${x.givenName} 様`)
          .join('\n')
      )
      .replace(/\{\{accountId\}\}/g, exhibitor.accountId || '')
      .replace(/\{\{password\}\}/g, exhibitor.password || '')
      .replace(
        /\{\{loginPageUrl\}\}/g,
        `${window.location.origin}/#/${exhibition.identifier}/login`
      )
      .replace(/\{\{companyName\}\}/g, exhibitor.companyName || '')
  } else if (type === 'remind') {
    return exhibition.remindNotificationMailBodyText.replace(
      /\{\{mail\}\}/g,
      targetUsers.map((x) => x.email).join('\n')
    )
  }
  return ''
}

export const genBadgeNotificationMailMessage = ({
  exhibition,
  targetEmail,
  badgeId,
}: {
  exhibition: ExhibitionDetailFragment
  badgeId: string
  targetEmail: string
}) => {
  return (
    exhibition.badgeNotificationMailBodyText
      .replace(/\{\{mail\}\}/g, targetEmail)
      // MARK: サーバ側でtoken付与
      .replace(
        /\{\{downloadUrl\}\}/g,
        `${window.location.origin}/#/download/badges/${badgeId}?token=`
      )
  )
}
