import React from 'react'
import { useHistory } from 'react-router-dom'
import { ExhibitionDetailFragment } from '../../api'
import { useAppContext } from '../../contexts/app'

const HeaderNav = (props: {
  isDownload?: boolean
  exhibition?: ExhibitionDetailFragment | null
}) => {
  const history = useHistory()
  const { authToken, logout } = useAppContext()

  return (
    <div className="header">
      <div>
        {!props.isDownload && '出展社専用バッジ申請サイト / Exhibitor Badge Application Site'}
        {props.isDownload && '出展社専用バッジダウンロードページ / Exhibitor Badge Download Page'}
      </div>
      {props.exhibition && authToken && !props.isDownload && (
        <div>
          <div
            className="btn"
            onClick={() => {
              logout()

              history.push(`/${props.exhibition!.identifier}/login`)
            }}
          >
            ログアウト / Logout
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderNav
