import React from 'react'
import { RouteProps } from 'react-router-dom'
import { ExhibitionDetailFragment } from '../../api'
import AdminSidebar from './AdminSidebar'
import HeaderNavAdmin from './HeaderNavAdmin'

type Props = RouteProps & { isWide?: boolean; exhibition?: ExhibitionDetailFragment }

const DefaultAdminLayout: React.FC<Props> = ({ children, isWide, exhibition }: Props) => {
  return (
    <>
      <AdminSidebar exhibition={exhibition} />
      <main className="main-content">
        <HeaderNavAdmin />
        <div className="main-container">
          <section className={'section' + (isWide ? ' wide-section' : '')}>{children}</section>
        </div>
      </main>
    </>
  )
}

export default DefaultAdminLayout
