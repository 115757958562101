import React from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentUserQuery } from '../api'

const QRCode = require('qrcode')

const BadgePage = () => {
  const { badgeId } = useParams<{ badgeId: string }>()
  const currentUser = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  })

  const exhibitor = currentUser.data?.currentUser
  const exhibition = exhibitor?.exhibition

  if (!exhibitor || !exhibition) {
    return <></>
  }

  const badge = exhibitor.badges.find((badge) => badge.id === badgeId)

  const badgeImage = new Image()

  badgeImage.src = exhibition.badgeBaseImageUrl

  setTimeout(() => {
    const front = document.getElementById('barcode_front') as HTMLCanvasElement
    const back = document.getElementById('barcode_back') as HTMLCanvasElement
    const qrcodeOptions = {
      errorCorrectionLevel: 'H',
    }
    if (front && badge?.barcodeId) {
      QRCode.toCanvas(front, badge?.barcodeId, qrcodeOptions)
    }
    if (back && badge?.barcodeId) {
      QRCode.toCanvas(back, badge?.barcodeId, qrcodeOptions)
    }
    const badgeCanvas = document.getElementById('badge') as HTMLCanvasElement
    const ctx = badgeCanvas.getContext('2d')
    badgeImage.onload = () => {
      if (ctx) {
        badgeCanvas.height = badgeImage.height
        badgeCanvas.width = badgeImage.width
        ctx.drawImage(badgeImage, 0, 0)
      }
    }
    if (ctx && badgeImage.complete) {
      badgeCanvas.height = badgeImage.height
      badgeCanvas.width = badgeImage.width
      ctx.drawImage(badgeImage, 0, 0)
    }
  })

  return (
    <div className="sportec2023">
      <div className="badge-wrapper">
        <canvas id="barcode_front"></canvas>
        <div className="badge-front text-center">
          <ul className="badge-jp">
            {badge?.companyName && <li className="company-name">{badge?.companyName}</li>}
            {badge?.department && <li>{badge?.department}</li>}
            {badge?.position && <li>{badge?.position}</li>}
            {badge?.displayName && <li>{badge?.displayName}</li>}
          </ul>
        </div>
        <canvas id="badge"></canvas>
      </div>
    </div>
  )
}

export default BadgePage
