import React from 'react'
import { BadgeModel } from '../api'
import AppService from '../services/app'

export const StateContext = React.createContext<(string | null)[] | null | undefined>(undefined)
export const AppServiceContext = React.createContext<AppService | undefined>(undefined)
export const DispatchContext = React.createContext<((token: string | null) => void)[] | undefined>(
  undefined
)

export const renderWithAppContext = (
  props: {
    authToken: string | null
    setAuthToken: (token: string | null) => void
    adminAuthToken: string | null
    setAdminAuthToken: (token: string | null) => void
    appService: AppService
  },
  children: React.ReactNode
) => {
  return (
    <StateContext.Provider value={[props.authToken, props.adminAuthToken]}>
      <DispatchContext.Provider value={[props.setAuthToken, props.setAdminAuthToken]}>
        <AppServiceContext.Provider value={props.appService}>{children}</AppServiceContext.Provider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const AppContextProvider: React.FC = ({ children }) => {
  const appService = new AppService()

  const [authToken, setAuthToken] = React.useState<string | null>(appService.getAuthToken() ?? null)

  const [adminAuthToken, setAdminAuthToken] = React.useState<string | null>(
    appService.getAuthToken('admin') ?? null
  )

  return renderWithAppContext(
    {
      authToken,
      setAuthToken,
      adminAuthToken,
      setAdminAuthToken,
      appService,
    },
    children
  )
}

export const useAuthToken = (type?: string) => {
  const contexts = React.useContext(StateContext)

  if (contexts === undefined || contexts === null) {
    throw new Error('useAuthToken must be used within a AppContextProvider')
  }
  let context
  if (type === 'admin') {
    context = contexts[1]
  } else {
    context = contexts[0]
  }
  return context
}

export const useSetAuthToken = (type?: string) => {
  const contexts = React.useContext(DispatchContext)
  if (contexts === undefined || contexts === null) {
    throw new Error('useSetAuthToken must be used within a AppContextProvider')
  }
  let context
  if (type === 'admin') {
    context = contexts[1]
  } else {
    context = contexts[0]
  }
  return context
}

export const useAppService = () => {
  const context = React.useContext(AppServiceContext)
  if (context === undefined) {
    throw new Error('useAppService must be used within a AppContextProvider')
  }
  return context
}

export const useAppContext = () => {
  const authToken = useAuthToken()
  const setAuthToken = useSetAuthToken()
  const adminAuthToken = useAuthToken('admin')
  const setAdminAuthToken = useSetAuthToken('admin')
  const appService = useAppService()

  const login = async (accountId: string, password: string, type?: string) => {
    const result = await appService.authenticate(accountId, password, type)
    if (type === 'admin') {
      setAdminAuthToken(result)
    } else {
      setAuthToken(result)
    }
  }

  const logout = (type?: string) => {
    appService.unauthenticate(type)
    if (type === 'admin') {
      setAdminAuthToken(null)
    } else {
      setAuthToken(null)
    }
  }

  const downloadBadge = async (badge: BadgeModel) => {
    const url = window.location.origin + '/#/badges/' + badge.id

    let res = await generatePdf(url)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', res, true)
    xhr.responseType = 'blob'
    xhr.onload = function (e) {
      const arrayBuffer = this.response

      const blob = new Blob([arrayBuffer], { type: 'application/pdf' })

      const blobUrl = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.href = blobUrl
      link.download = badge.companyName + '-' + badge.displayName + '-バッジ.pdf'
      link.click()
    }
    xhr.send()
  }

  const generatePdf = async (url: string) => {
    if (!authToken) {
      throw new Error()
    }
    const response = await appService.generatePdf(url, authToken)
    return response.data.url
  }

  const uploadExhibitors = async (params: FormData) => {
    if (!adminAuthToken) {
      throw new Error()
    }
    const response = await appService.uploadExhibitors(adminAuthToken, params)
    return response.data
  }

  const uploadBadges = async (params: FormData) => {
    if (!authToken) {
      throw new Error()
    }
    const response = await appService.uploadBadges(authToken, params)
    return response.data
  }

  return {
    authToken,
    setAuthToken,
    adminAuthToken,
    setAdminAuthToken,
    login,
    logout,
    generatePdf,
    downloadBadge,
    uploadExhibitors,
    uploadBadges,
  }
}
