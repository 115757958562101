import React from 'react'
import { RouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { useAuthToken } from '../../contexts/app'

type Props = RouteProps & { fallbackPath: string }

const AdminPrivateRoute: React.FC<Props> = (props: Props) => {
  const { component, children, fallbackPath, ...rest } = props
  const authToken = useAuthToken('admin')

  if (!props.location?.pathname.startsWith('/admin')) return <></>

  const renderNextRoute = (routeProps: RouteProps) => {
    if (!authToken) {
      return (
        <Redirect
          to={{
            pathname: fallbackPath,
            state: { from: routeProps.location },
          }}
        />
      )
    }
    return <Route {...props} />
  }

  return <Route {...rest} render={renderNextRoute} />
}

export default AdminPrivateRoute
