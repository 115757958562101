import React from 'react'
import { RouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { useAuthToken } from '../../contexts/app'

type Props = RouteProps & { fallbackPath: string | ((routeProps: RouteProps) => string) }

const PrivateRoute: React.FC<Props> = (props: Props) => {
  const { component, children, fallbackPath, ...rest } = props
  const authToken = useAuthToken()

  if (props.location?.pathname.startsWith('/admin')) return <></>

  const renderNextRoute = (routeProps: RouteProps) => {
    if (!authToken) {
      return (
        <Redirect
          to={{
            pathname: typeof fallbackPath === 'string' ? fallbackPath : fallbackPath(routeProps),
            state: { from: routeProps.location },
          }}
        />
      )
    }
    return <Route {...props} />
  }

  return <Route {...rest} render={renderNextRoute} />
}

export default PrivateRoute
